import React, { useEffect, useState } from 'react';
import './style.css';
import { HeaderCardapio } from '../../../Components/HeaderCardapio';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';

const Sucesso = () => {
  const [data, setData] = useState(null); // Adicionado o estado para armazenar os dados da API
  const location = useLocation();
  const { cliente_id } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const apartamento = queryParams.get('x');
  const cliente_url = queryParams.get('id_cliente');
  const session_control = queryParams.get('session_control');
  const navigate = useNavigate(); // Hook para navegação programática

  const userLanguage = navigator.language || navigator.userLanguage; // Obtém o idioma do navegador

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Pegando o IP do usuário e o cookie da sessão do localStorage
        const userIp = localStorage.getItem('user_ip');
        const sessionCookie = localStorage.getItem('session_cookie');

        const body = JSON.stringify({
          cliente_id: cliente_id,
          ip_address: userIp,
          language: userLanguage,
          session: sessionCookie,
        });

        const response = await fetch(
          `${process.env.REACT_APP_URL_API}/apiv2/index/?x=${apartamento}&id_cliente=${cliente_url}&session_control=${session_control}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: body, // Enviando o corpo da requisição
          },
        );

        if (!response.ok) {
          throw new Error('Erro ao buscar dados da API');
        }

        const data = await response.json();
        setData(data); // Atualizando o estado com os dados recebidos
        console.log('Dados recebidos da API:', data);
      } catch (error) {
        console.error('Erro na chamada da API:', error);
      }
    };

    fetchData();

    const redirectTimeout = setTimeout(() => {
      navigate(
        `/${cliente_id}/?x=${apartamento}&id_cliente=${cliente_url}&session_control=${session_control}`,
      );
    }, 5000); // 5000 milissegundos = 5 segundos

    return () => clearTimeout(redirectTimeout); // Limpa o timeout quando o componente desmonta
  }, [apartamento, cliente_url, session_control, cliente_id]);
  if (data === null) return null;

  const cor2 = data.cliente[0]?.cor_1;
  const cor_letra = data.cliente[0]?.cor_letra;
  {
    console.log('cor2', data);
  }
  return (
    <div>
      <HeaderCardapio />

      <div class="MainGrid">
        <div className="container__success ">
          <div
            style={{
              textAlign: 'center',
              padding: '10px',
            }}
          >
            <svg
              class="checkmark"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 52 52"
            >
              <circle
                class="checkmark__circle"
                cx="26"
                cy="26"
                r="25"
                fill="none"
              />
              <path
                class="checkmark__check"
                fill="none"
                d="M14.1 27.2l7.1 7.2 16.7-16.8"
              />
            </svg>
            <p>O seu pedido foi realizado com sucesso!</p>
          </div>
        </div>
        <div className="div-btn-carrinho">
          <Link
            to={`/${cliente_id}/?x=${apartamento}&id_cliente=${cliente_url}&session_control=${session_control}`}
            className="btn-sucess"
            style={{
              background:
                cor2 === 'outra'
                  ? cor2
                  : {
                      'azul-escuro': '#3d489b',
                      azul: '#4892DF',
                      verde: '#3BBF9F',
                      cinza: '#A6B1B4',
                      'cinza-solido': '#f2f2f2',
                      vermelho: '#FF5E5E',
                      laranja: '#FF9A38',
                      amarelo: '#FFFF00',
                      roxo: '#800080',
                      dourado: '#b99c56',
                      'azul-marinho': '#1C7FBC',
                      'azul-solido': '#315694',
                      marrom: '#673C0D',
                      branco: '#FFFFFF',
                    }[cor2] || cor2,
              color: cor_letra,
            }}
          >
            Voltar
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Sucesso;
