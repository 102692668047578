import React, { useState, useEffect } from 'react';
import './style.css';
import Cookies from 'universal-cookie';
import { Link, useParams, useLocation } from 'react-router-dom';
import LogoClube from '../../Assets/logoClube.svg';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import ButtonLink from '../../Components/ButtonLink/ButtonLink';

const { REACT_APP_URL_API } = process.env;

const style = {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'var(--background)',
  border: '2px solid var(--title)',
  boxShadow: 24,
  p: 4,
};

const generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const Botaofacilities = () => {
  const [data, setData] = useState(null);

  const { cliente_id } = useParams();
  const location = useLocation();
  const handleFechar1 = () => setAbrir1(false);
  const [abrir1, setAbrir1] = useState(false);
  const handleAbrir1 = () => setAbrir1(true);

  const queryParams = new URLSearchParams(location.search);
  const apartamento = queryParams.get('x');
  const cliente_url = queryParams.get('id_cliente');
  const session_control = queryParams.get('session_control');

  const cookies = new Cookies();
  const sessionCookieName = 'app_sessionid'; // Nome personalizado para o cookie de sessão

  const [session, setSession] = useState(
    cookies.get(sessionCookieName) || generateUUID(),
  );

  useEffect(() => {
    // Se o cookie não existir, crie um novo e salve
    if (!cookies.get(sessionCookieName)) {
      cookies.set(sessionCookieName, session, { path: '/' });
    }
  }, [session, cookies]);

  localStorage.setItem('session', session);

  useEffect(() => {
    const ultrapassouLimite = localStorage.getItem('ultrapassou_limite');

    if (ultrapassouLimite === 'true') {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  }, [localStorage.getItem('ultrapassou_limite')]);

  useEffect(() => {
    if (data && data.cliente && data.cliente.length > 0) {
      const horaLeitura = data.cliente[0]?.additional_info?.hora_leitura;
      const tempoSessao = data.cliente[0]?.additional_info?.now;

      if (horaLeitura) {
        localStorage.setItem('hora_leitura', horaLeitura);
      }
      if (tempoSessao) {
        localStorage.setItem('tempo_sessao', tempoSessao);
      }

      const storedHoraLeitura = localStorage.getItem('hora_leitura');
      const storedTempoSessao = localStorage.getItem('tempo_sessao');

      const tempoPassado =
        new Date().getTime() - new Date(storedHoraLeitura).getTime();

      if (tempoPassado >= 60 * 60 * 1000) {
        const newSession = generateUUID();
        setSession(newSession);
        cookies.set(sessionCookieName, newSession, { path: '/' });
      }
    }
  }, [data, cookies]);

  const userLanguage = navigator.language || navigator.userLanguage; // Obtém o idioma do navegador

  useEffect(() => {
    const fetchIpAndSendData = async () => {
      try {
        const ipResponse = await fetch('https://api.ipify.org?format=json');
        const ipData = await ipResponse.json();
        const userIp = ipData.ip;

        localStorage.setItem('ip_address', userIp);

        const cookies = new Cookies();

        // Remove o cookie "sessionid" se ele existir
        cookies.remove('sessionid', { path: '/' });

        // Obtém ou cria o cookie "app_sessionid"
        let sessionCookie = cookies.get('app_sessionid');
        if (!sessionCookie) {
          sessionCookie = generateUUID();
          cookies.set('app_sessionid', sessionCookie, {
            path: '/',
            maxAge: 3600,
          });
        }

        setSession(sessionCookie);

        const headers = new Headers({
          'Content-Type': 'application/json',
        });

        const body = JSON.stringify({
          cliente_id: cliente_id,
          ip_address: userIp,
          language: userLanguage,
          session: sessionCookie,
        });

        const indexResponse = await fetch(
          `${REACT_APP_URL_API}/apiv2/index/?x=${apartamento}&id_cliente=${cliente_url}&session_control=${session_control}`,
          {
            method: 'POST',
            credentials: 'omit',
            headers,
            body,
          },
        );

        const json = await indexResponse.json();

        if (json?.cliente && Array.isArray(json.cliente)) {
          setData(json);
          const ultrapassouLimite =
            json.cliente[0]?.additional_info?.ultrapassou_limite;
          if (ultrapassouLimite !== undefined) {
            localStorage.setItem('ultrapassou_limite', ultrapassouLimite);
          }
        } else {
          console.error('Estrutura de dados inesperada:', json);
        }

        const menuResponse = await fetch(
          `${REACT_APP_URL_API}/apiv2/menu/?x=${apartamento}&id_cliente=${cliente_url}&session_control=${session_control}`,
          {
            method: 'POST',
            credentials: 'omit',
            headers,
            body,
          },
        );

        const menuJson = await menuResponse.json();
        console.log(menuJson);
      } catch (error) {
        console.error('Erro ao obter o IP do usuário ou enviar dados:', error);
      }
    };

    fetchIpAndSendData();
  }, [cliente_id, apartamento, cliente_url, session, session_control]);

  // useEffect(() => {
  //   const loadAdSense = () => {
  //     try {
  //       const ads = document.querySelectorAll('.adsbygoogle');
  //       ads.forEach((ad) => {
  //         if (!ad.hasAttribute('data-ad-status')) {
  //           (window.adsbygoogle = window.adsbygoogle || []).push({});
  //         }
  //       });
  //     } catch (e) {
  //       console.error('Adsense error: ', e);
  //     }
  //   };

  //   if (
  //     !document.querySelector(
  //       'script[src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"]',
  //     )
  //   ) {
  //     const script = document.createElement('script');
  //     script.src =
  //       'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3671789793368266';
  //     script.async = true;
  //     script.crossOrigin = 'anonymous';

  //     script.onload = loadAdSense;
  //     document.body.appendChild(script);
  //   } else {
  //     loadAdSense();
  //   }
  // }, []);

  if (data === null) return null;

  const cor1 = data.cliente[0]?.cor_custom;
  const cor2 = data.cliente[0]?.cor_custom2;
  const cor_letra = data.cliente[0]?.cor_letra;

  return (
    <>
      {data.cliente[0]?.front_novo === true ? (
        <div className="container__btn_facilities">
          {data.cliente.map((item, index) =>
            item.menu
              .filter((btn) => btn.status === true)
              .sort((a, b) => a.ordem - b.ordem)
              .map((btn, idx) => (
                <div
                  key={btn.id}
                  className={`btn_facilities ${
                    idx % 3 === 0 ? 'full-width' : 'half-width'
                  }`}
                >
                  {btn.tipo === 'CA' && (
                    <Link
                      to={`/${cliente_id}/cardapio/tipo/?x=${apartamento}&id_cliente=${cliente_url}&session_control=${session_control}`}
                      className="btn_facilities_link"
                      style={{
                        backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)),url(${
                          `${REACT_APP_URL_API}` + btn.img
                        })`,
                      }}
                    >
                      <p>{btn.nome}</p>
                    </Link>
                  )}
                  {btn.tipo === 'DI' && (
                    <Link
                      to={`/${cliente_id}/diretorio/?x=${apartamento}&id_cliente=${cliente_url}&session_control=${session_control}`}
                      className="btn_facilities_link"
                      style={{
                        backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)),url(${
                          `${REACT_APP_URL_API}` + btn.img
                        })`,
                      }}
                    >
                      <p>{btn.nome}</p>
                    </Link>
                  )}
                  {btn.tipo === 'EX' && (
                    <Link
                      to={`/${cliente_id}/extrato/?x=${apartamento}&id_cliente=${cliente_url}&session_control=${session_control}`}
                      className="btn_facilities_link"
                      style={{
                        backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)),url(${
                          `${REACT_APP_URL_API}` + btn.img
                        })`,
                      }}
                    >
                      <p>{btn.nome}</p>
                    </Link>
                  )}
                  {btn.tipo === 'CO' && (
                    <Link
                      to={`/${cliente_id}/concierge/?x=${apartamento}&id_cliente=${cliente_url}&session_control=${session_control}`}
                      className="btn_facilities_link"
                      style={{
                        backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)),url(${
                          `${REACT_APP_URL_API}` + btn.img
                        })`,
                      }}
                    >
                      <p>{btn.nome}</p>
                    </Link>
                  )}
                  {btn.tipo === 'CV' && (
                    <a
                      href="https://net4guest.com/"
                      target="_blank"
                      className="btn_facilities_link"
                      style={{
                        backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)),url(https://blog.viajarfazbem.com/wp-content/webp-express/webp-images/uploads/2019/02/original-60fa3fe0e7aae3a872c8222edeb6f18b.jpg.webp)`,
                      }}
                    >
                      <p>Clube de Vantagens</p>
                    </a>
                  )}

                  {btn.tipo === 'GE' && (
                    <a
                      // onClick={handleAbrir1}
                      className="btn_facilities_link"
                      href={btn.url}
                      target="_blank"
                      style={{
                        backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)),url(${
                          `${REACT_APP_URL_API}` + btn.img
                        })`,
                      }}
                    >
                      <p>{btn.nome}</p>
                    </a>
                  )}
                </div>
              )),
          )}
        </div>
      ) : (
        <div>
          {data.cliente.map((item, index) => (
            <div className="container2__btn_facilities" key={index}>
              {item.menu
                .filter((btn) => btn.status === true)
                .sort((a, b) => a.ordem - b.ordem)
                .map((btn) => (
                  <div key={btn.id}>
                    {btn.tipo === 'CA' && (
                      <Link
                        to={`/${cliente_id}/cardapio/tipo/?x=${apartamento}&id_cliente=${cliente_url}&session_control=${session_control}`}
                        className="btn2_facilities"
                        style={{
                          background:
                            btn.cor_1 === 'outra'
                              ? btn.cor_custom
                              : {
                                  'azul-escuro': '#3d489b',
                                  azul: '#4892DF',
                                  verde: '#3BBF9F',
                                  cinza: '#A6B1B4',
                                  'cinza-solido': '#f2f2f2',
                                  vermelho: '#FF5E5E',
                                  laranja: '#FF9A38',
                                  amarelo: '#FFFF00',
                                  roxo: '#800080',
                                  dourado: '#b99c56',
                                  'azul-marinho': '#1C7FBC',
                                  'azul-solido': '#315694',
                                  marrom: '#673C0D',
                                  branco: '#FFFFFF',
                                }[btn.cor_1] || btn.cor_1,
                          color: cor_letra,
                        }}
                        // onClick={handleAbrir1}
                      >
                        <img src={`${REACT_APP_URL_API}` + btn.img} alt="" />

                        <p style={{ color: cor_letra }}>{btn.nome}</p>
                      </Link>
                    )}

                    {btn.tipo === 'DI' && (
                      <Link
                        to={`/${cliente_id}/diretorio/?x=${apartamento}&id_cliente=${cliente_url}&session_control=${session_control}`}
                        className="btn2_facilities"
                        style={{
                          background:
                            btn.cor_1 === 'outra'
                              ? btn.cor_custom
                              : {
                                  'azul-escuro': '#3d489b',
                                  azul: '#4892DF',
                                  verde: '#3BBF9F',
                                  cinza: '#A6B1B4',
                                  'cinza-solido': '#f2f2f2',
                                  vermelho: '#FF5E5E',
                                  laranja: '#FF9A38',
                                  amarelo: '#FFFF00',
                                  roxo: '#800080',
                                  dourado: '#b99c56',
                                  'azul-marinho': '#1C7FBC',
                                  'azul-solido': '#315694',
                                  marrom: '#673C0D',
                                  branco: '#FFFFFF',
                                }[btn.cor_1] || btn.cor_1,
                        }}
                      >
                        <img src={`${REACT_APP_URL_API}` + btn.img} alt="" />

                        <p style={{ color: cor_letra }}>{btn.nome}</p>
                      </Link>
                    )}

                    {btn.tipo === 'EX' && (
                      <Link
                        to={`/${cliente_id}/extrato/?x=${apartamento}&id_cliente=${cliente_url}&session_control=${session_control}`}
                        className="btn2_facilities"
                        style={{
                          background:
                            btn.cor_1 === 'outra'
                              ? btn.cor_custom
                              : {
                                  'azul-escuro': '#3d489b',
                                  azul: '#4892DF',
                                  verde: '#3BBF9F',
                                  cinza: '#A6B1B4',
                                  'cinza-solido': '#f2f2f2',
                                  vermelho: '#FF5E5E',
                                  laranja: '#FF9A38',
                                  amarelo: '#FFFF00',
                                  roxo: '#800080',
                                  dourado: '#b99c56',
                                  'azul-marinho': '#1C7FBC',
                                  'azul-solido': '#315694',
                                  marrom: '#673C0D',
                                  branco: '#FFFFFF',
                                }[btn.cor_1] || btn.cor_1,
                          color: cor_letra,
                        }}
                      >
                        <img src={`${REACT_APP_URL_API}` + btn.img} alt="" />

                        <p style={{ color: cor_letra }}>{btn.nome}</p>
                      </Link>
                    )}
                    {btn.tipo === 'CO' && (
                      <Link
                        to={`/${cliente_id}/concierge/?x=${apartamento}&id_cliente=${cliente_url}&session_control=${session_control}`}
                        className="btn2_facilities"
                        style={{
                          background:
                            btn.cor_1 === 'outra'
                              ? btn.cor_custom
                              : {
                                  'azul-escuro': '#3d489b',
                                  azul: '#4892DF',
                                  verde: '#3BBF9F',
                                  cinza: '#A6B1B4',
                                  'cinza-solido': '#f2f2f2',
                                  vermelho: '#FF5E5E',
                                  laranja: '#FF9A38',
                                  amarelo: '#FFFF00',
                                  roxo: '#800080',
                                  dourado: '#b99c56',
                                  'azul-marinho': '#1C7FBC',
                                  'azul-solido': '#315694',
                                  marrom: '#673C0D',
                                  branco: '#FFFFFF',
                                }[btn.cor_1] || btn.cor_1,
                          color: cor_letra,
                        }}
                      >
                        <img src={`${REACT_APP_URL_API}` + btn.img} alt="" />

                        <p style={{ color: cor_letra }}>{btn.nome}</p>
                      </Link>
                    )}

                    {btn.tipo === 'CV' && (
                      <a
                        href="https://net4guest.com/"
                        target="_blank"
                        className="btn2_facilities"
                        style={{
                          background:
                            btn.cor_1 === 'outra'
                              ? btn.cor_custom
                              : {
                                  'azul-escuro': '#3d489b',
                                  azul: '#4892DF',
                                  verde: '#3BBF9F',
                                  cinza: '#A6B1B4',
                                  'cinza-solido': '#f2f2f2',
                                  vermelho: '#FF5E5E',
                                  laranja: '#FF9A38',
                                  amarelo: '#FFFF00',
                                  roxo: '#800080',
                                  dourado: '#b99c56',
                                  'azul-marinho': '#1C7FBC',
                                  'azul-solido': '#315694',
                                  marrom: '#673C0D',
                                  branco: '#FFFFFF',
                                }[btn.cor_1] || btn.cor_1,
                          color: cor_letra,
                        }}
                      >
                        <img src={LogoClube} alt="" />
                        <p style={{ color: cor_letra }}>Clube de Vantagens</p>
                      </a>
                    )}

                    {btn.tipo === 'GE' && (
                      <a
                        href={btn.url}
                        className="btn2_facilities"
                        target="_blank"
                        style={{
                          background:
                            btn.cor_1 === 'outra'
                              ? btn.cor_custom
                              : {
                                  'azul-escuro': '#3d489b',
                                  azul: '#4892DF',
                                  verde: '#3BBF9F',
                                  cinza: '#A6B1B4',
                                  'cinza-solido': '#f2f2f2',
                                  vermelho: '#FF5E5E',
                                  laranja: '#FF9A38',
                                  amarelo: '#FFFF00',
                                  roxo: '#800080',
                                  dourado: '#b99c56',
                                  'azul-marinho': '#1C7FBC',
                                  'azul-solido': '#315694',
                                  marrom: '#673C0D',
                                  branco: '#FFFFFF',
                                }[btn.cor_1] || btn.cor_1,
                          color: cor_letra,
                        }}
                      >
                        <img src={`${REACT_APP_URL_API}` + btn.img} alt="" />
                        <p style={{ color: cor_letra }}>{btn.nome}</p>
                      </a>
                    )}
                  </div>
                ))}

              {/* <div
                className="btn2_facilities"
                style={{
                  background: cor1,
                }}
              >
                <img
                  src="https://facilities.net4guest.com/media/859/icon_suporte.png"
                  alt=""
                />
                <p>Suporte</p>
              </div> */}

              <Modal
                open={abrir1}
                onClose={handleFechar1}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <div>
                    <p
                      style={{
                        color: 'var(--title)',
                        padding: '10px',
                        marginBottom: '20px',
                      }}
                    >
                      Para onde voce quer atendimento ?
                    </p>
                    <div>
                      <ButtonLink to={`/${cliente_id}/cardapio`}>
                        {' '}
                        Restaurante
                      </ButtonLink>
                      <ButtonLink to={`/${cliente_id}/cardapio`}>
                        {' '}
                        Room Service
                      </ButtonLink>
                    </div>
                    <button
                      className="close"
                      onClick={handleFechar1}
                      style={{
                        background: `${cor1}`,
                      }}
                    >
                      X
                    </button>
                  </div>
                </Box>
              </Modal>
            </div>
          ))}
        </div>
      )}
      {data.cliente[0]?.mostra_ads_primeira_pagina && (
        <div className="div-ads">
          <ins
            className="adsbygoogle"
            style={{ display: 'inline-block', width: '100%', height: '100%' }}
            data-ad-client="ca-pub-3671789793368266"
            data-ad-slot="7389340169"
          ></ins>
        </div>
      )}

      <Modal
        open={abrir1}
        onClose={handleFechar1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="content_modal1">
            <p>
              Não é possível acessar o Concierge. Ultrapassou o limite de acesso
              permitido.
            </p>
            <ButtonLink
              to={`/${cliente_id}`}
              text="Voltar"
              color={cor2 ? cor2 : 'var(--button_facilities)'}
            />
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default Botaofacilities;
