import React, { useState } from 'react';
import './style.css';

const ContadorCardapio = ({ quantidade, onQuantidadeChange }) => {
  const handleIncrement = () => {
    onQuantidadeChange(quantidade + 1);
  };

  const handleDecrement = () => {
    if (quantidade > 0) {
      onQuantidadeChange(quantidade - 1);
    }
  };

  return (
    <div>
      <div className="container-counter">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <button className="btn-counter-remove" onClick={handleDecrement}>
            -
          </button>{' '}
          <div>{quantidade}</div>
          <button className="btn-counter-add" onClick={handleIncrement}>
            +
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContadorCardapio;
