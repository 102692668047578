import React, { useEffect } from 'react';
import './style.css';
import { IconContext } from 'react-icons';
import { AiFillInstagram, AiFillYoutube } from 'react-icons/ai';
import RoomServiceIcon from '@mui/icons-material/RoomService';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Cookies from 'universal-cookie';

import { useParams, useLocation } from 'react-router-dom';

import LogoClienteFacilities from '../LogoClienteFacilities';

const { REACT_APP_URL_API } = process.env;

export function HeaderFacilities() {
  let [social, setSocial] = React.useState(null);
  const { cliente_id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const apartamento = queryParams.get('x');
  const cliente_url = queryParams.get('id_cliente');
  const session_control = queryParams.get('session_control');

  const userLanguage = navigator.language || navigator.userLanguage;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userIp = localStorage.getItem('ip_address');
        const session = localStorage.getItem('session');
        const token = new Cookies().get('csrftoken2');

        const headers = new Headers({
          'Content-Type': 'application/json',
          'X-CSRFToken': token,
        });

        const body = JSON.stringify({
          cliente_id: cliente_id,
          ip_address: userIp,
          session: session,
          language: userLanguage,
        });

        const response = await fetch(
          `${REACT_APP_URL_API}/apiv2/index/?x=${apartamento}&id_cliente=${cliente_url}&session_control=${session_control}`,
          {
            method: 'POST',
            credentials: 'omit',
            headers,
            body,
          },
        );

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Erro ${response.status}: ${errorText}`);
        }

        const json = await response.json();
        setSocial(json);
      } catch (error) {
        console.error('Erro ao obter dados:', error);
      }
    };

    fetchData();
  }, [cliente_id, apartamento, cliente_url, session_control]);

  const mainURL = window.location.href;
  const mainUrlParam = mainURL.split('/');
  const protocol = mainUrlParam[4];

  if (social === null) return null;

  return (
    <header className="header">
      <div className="container" style={{ gap: '0px' }}>
        {protocol[0] !== '?' ? (
          <a href="javascript:history.back()" className="btn-header-return">
            <ArrowBackIosIcon />
          </a>
        ) : null}
        {console.log('mainUrlParam', protocol[0])}
        <LogoClienteFacilities />

        <div className="menu-icons">
          <IconContext.Provider value={{ size: '26px' }}>
            {/* <div>
              <a href={social.instagram} target="blank">
                <AiFillInstagram />
              </a>
            </div>

            <div>
              <a href={social.youtube} target="blank">
                <AiFillYoutube />
              </a>
            </div> */}

            <div>
              <div className="card_room">
                <RoomServiceIcon />

                <p>{social.cliente[0].additional_info.apto}</p>
              </div>
            </div>
          </IconContext.Provider>
        </div>
      </div>
    </header>
  );
}
