import React, { useState, useEffect } from 'react';
import './style.css';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { HeaderFacilities } from '../../../Components/HeaderFacilities';
import { Link } from 'react-scroll';
import { useParams, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
const { REACT_APP_URL_API } = process.env;

const Diretorio = () => {
  const [data, setData] = useState(null);
  const [diretorio, setDiretorio] = useState(null);
  const [value, setValue] = useState(0);
  const { cliente_id } = useParams();
  const location = useLocation();
  const cookies = new Cookies();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Extrai parâmetros da URL
  const queryParams = new URLSearchParams(location.search);
  const apartamento = queryParams.get('x');
  const cliente_url = queryParams.get('id_cliente');
  const session_control = queryParams.get('session_control');

  // Recupera valores de ip_address e session do localStorage
  const userIp = localStorage.getItem('ip_address');
  const session = localStorage.getItem('session');

  const userLanguage = navigator.language || navigator.userLanguage; // Obtém o idioma do navegador

  const raw = JSON.stringify({
    cliente_id: cliente_id,
    ip_address: userIp,
    language: userLanguage,
    session: session,
  });

  const headers = new Headers({
    'Content-Type': 'application/json',
    'X-CSRFToken': cookies.get('csrftoken2'),
  });

  useEffect(() => {
    fetch(
      `${REACT_APP_URL_API}/apiv2/index/?x=${apartamento}&id_cliente=${cliente_url}&session_control=${session_control}`,
      {
        method: 'POST',
        credentials: 'omit',

        headers: headers,
        body: raw,
      },
    )
      .then((response) => response.json())
      .then((json) => {
        setData(json);
      })
      .catch((error) => console.error('Erro ao obter os dados:', error));
  }, [cliente_id, apartamento, cliente_url, session_control]);

  useEffect(() => {
    fetch(
      `${REACT_APP_URL_API}/apiv2/directory/?x=${apartamento}&id_cliente=${cliente_url}&session_control=${session_control}`,
      {
        method: 'POST',
        credentials: 'omit',

        headers: headers,
        body: raw,
      },
    )
      .then((response) => response.json())
      .then((json) => {
        setDiretorio(json);
      })
      .catch((error) => console.error('Erro ao obter os dados:', error));
  }, [cliente_id, apartamento, cliente_url, session_control]);

  if (data === null || diretorio === null) return null;

  const cor1 = data.cliente[0]?.cor_custom;
  const cor2 = data.cliente[0]?.cor_1;

  const tvItems = diretorio.diretorio.filter((item) => item.tv);
  const nonTvItems = diretorio.diretorio.filter((item) => !item.tv);

  return (
    <div>
      <HeaderFacilities />

      <Box
        sx={{
          maxWidth: '930px',
          margin: '120px auto',
          left: '1%',
          right: '1%',
          bgcolor: 'var(--background-main)',
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons={true}
          aria-label="scrollable prevent tabs example"
        >
          <Tab label="Serviços" style={{ color: 'var(--secondary)' }} />
          {tvItems[0]?.tv && (
            <Tab label="Canais de TV" style={{ color: 'var(--secondary)' }} />
          )}
        </Tabs>

        {value === 0 && (
          <div className="MainGrid" style={{ marginBottom: '-60px' }}>
            <div className="div-container-diretorio-servicos">
              <div className="categorias categorias-cardapio">
                <ul style={{ marginTop: '90px' }}>
                  {nonTvItems.map((item, index) => (
                    <li key={index}>
                      <Link
                        className={item.nome_item}
                        to={item.nome_item}
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={-99}
                        activeClass="ActiveLink"
                      >
                        {item.nome_item}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>

              <Box
                sx={{
                  maxWidth: '930px',
                  margin: '170px auto',
                  left: '1%',
                  right: '1%',
                  bgcolor: 'var(--background-main)',
                }}
              >
                {nonTvItems.map((item, index) => (
                  <div
                    key={index}
                    className={item.nome_item}
                    name={item.nome_item}
                    id={item.nome_item}
                  >
                    <div className="container-clube-voucher">
                      <h2
                        style={{
                          color: 'var(--secondary)',
                          fontSize: '18px',
                          fontWeight: 300,
                          padding: '0px 0px 10px 10px',
                        }}
                      >
                        {item.nome_item}
                      </h2>

                      {item.item_diretorio_sem_cat.map((subItem, subIndex) => (
                        <div key={subIndex}>
                          <div className="box container-card-cardapio">
                            <div className="concierge_diretorio">
                              {/* <div>
                                <img
                                  src="https://via.placeholder.com/150"
                                  alt=""
                                />
                              </div> */}

                              <div className="counteudo-servicos">
                                <p>
                                  <b>{subItem.nome}</b>
                                </p>
                                <p>{subItem.descricao}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </Box>
            </div>
          </div>
        )}

        {value === 1 && (
          <div className="MainGrid" style={{ marginBottom: '-60px' }}>
            <div
              className="box"
              style={{
                margin: '10px auto',
                border: 'none',
                background: 'var(--background-main)',
              }}
            >
              <div className="div-title-canais-tv">
                <h1>Canais de Tv</h1>
              </div>

              <div className="container-card-tv">
                {tvItems[0].programacao_tv.map((item, index) => (
                  <div key={index} className="div-tv-card box">
                    <div
                      className="div-numero-canal"
                      style={{
                        background:
                          cor2 === 'outra'
                            ? cor2
                            : {
                                'azul-escuro': '#3d489b',
                                azul: '#4892DF',
                                verde: '#3BBF9F',
                                cinza: '#A6B1B4',
                                'cinza-solido': '#f2f2f2',
                                vermelho: '#FF5E5E',
                                laranja: '#FF9A38',
                                amarelo: '#FFFF00',
                                roxo: '#800080',
                                dourado: '#b99c56',
                                'azul-marinho': '#1C7FBC',
                                'azul-solido': '#315694',
                                marrom: '#673C0D',
                                branco: '#FFFFFF',
                              }[cor2] || cor2,
                      }}
                    >
                      <p>{item.canal}</p>
                    </div>
                    <div className="div-nome-canal">
                      <p>{item.nome}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </Box>
    </div>
  );
};

export default Diretorio;
