import React, { useEffect, useState } from 'react';
import './style.css';
import { Link, useParams, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';

const { REACT_APP_URL_API } = process.env;

const LogoClienteFacilities = () => {
  const [data, setData] = useState(null);
  const { cliente_id } = useParams();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const apartamento = queryParams.get('x');
  const cliente_url = queryParams.get('id_cliente');
  const session_control = queryParams.get('session_control');

  const userLanguage = navigator.language || navigator.userLanguage; // Obtém o idioma do navegador

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userIp = localStorage.getItem('ip_address');
        const session = localStorage.getItem('session');
        const token = new Cookies().get('csrftoken2');

        const headers = new Headers({
          'Content-Type': 'application/json',
          'X-CSRFToken': token,
        });

        const body = JSON.stringify({
          cliente_id: cliente_id,
          ip_address: userIp,
          language: userLanguage,
          session: session,
        });

        const response = await fetch(
          `${REACT_APP_URL_API}/apiv2/index/?x=${apartamento}&id_cliente=${cliente_url}&session_control=${session_control}`,
          {
            method: 'POST',
            credentials: 'omit',
            headers,
            body,
          },
        );

        const json = await response.json();
        setData(json);
      } catch (error) {
        console.error('Erro ao obter dados:', error);
      }
    };

    fetchData();
  }, [cliente_id, apartamento, cliente_url, session_control]);

  if (data === null) return null;

  return (
    <div className="container">
      {data.cliente.map((item, index) => (
        <Link
          to={`/${cliente_id}/?x=${apartamento}&id_cliente=${cliente_url}&session_control=${session_control}`}
          key={index}
        >
          <img
            className="logo"
            src={`${REACT_APP_URL_API}` + item.logo}
            alt="Logo Cliente"
          />
        </Link>
      ))}
    </div>
  );
};

export default LogoClienteFacilities;
