import React, { useState, useEffect } from 'react';
import './style.css';
import { HeaderFacilities } from '../../../Components/HeaderFacilities';
import { useParams, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
const { REACT_APP_URL_API } = process.env;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'var(--table)',
    color: 'var(--primary)',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: 'var(--secondary)',
    backgroundColor: 'var(--table)',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'var(--background)',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(date, description, value) {
  return { date, description, value };
}

const rows = [
  createData('19/05/2019 01:40:00', 'Diaria / Rate', 'R$ 265.00'),
  createData('19/05/2019 01:40:00	', '	ISS / Service tax	', 'R$ 13.25'),
  createData(
    '19/05/2019 01:40:00',
    'Estacionamento/ Valet Parking	 / Rate',
    'R$ 60.00',
  ),
  createData('19/05/2019 01:40:00', 'Room Service	', 'R$ 44.00'),
  createData('19/05/2019 01:40:00', 'Frigobar / Mini-bar', 'R$ 7.50'),
];

export const Extrato = () => {
  let [data, setData] = React.useState(null);
  const { cliente_id } = useParams();
  const location = useLocation();
  const [error, setError] = useState(null);

  // Extrai parâmetros da URL
  const queryParams = new URLSearchParams(location.search);
  const apartamento = queryParams.get('x');
  const cliente_url = queryParams.get('id_cliente');
  const session_control = queryParams.get('session_control');

  // Recupera valores de ip_address e session do localStorage
  const userIp = localStorage.getItem('ip_address');
  const session = localStorage.getItem('session');

  const userLanguage = navigator.language || navigator.userLanguage; // Obtém o idioma do navegador

  const raw = JSON.stringify({
    cliente_id: cliente_id,
    ip_address: userIp,
    language: userLanguage,
    session: session,
  });

  const cookies = new Cookies();
  const token = cookies.get('csrftoken2');

  const headers = new Headers({
    'Content-Type': 'application/json',
    'X-CSRFToken': token,
  });

  useEffect(() => {
    window
      .fetch(
        `${REACT_APP_URL_API}/apiv2/index/?x=${apartamento}&id_cliente=${cliente_url}&session_control=${session_control}`,
        {
          method: 'POST',
          credentials: 'omit',

          headers: headers,
          body: raw,
        },
      )
      .then((response) => response.json())
      .then((json) => setData(json))
      .catch((error) => {
        console.error('Erro ao obter os dados:', error);
        setError(error);
      });
  }, [cliente_id, apartamento, cliente_url, session_control]);

  if (data === null) return null;
  const cor1 = data.cliente[0]?.cor_custom;

  return (
    <div>
      <HeaderFacilities />
      <div className="MainGrid">
        <div className="firstColumn">
          <div className="div-extrato-cliente-name">
            <h1>{cliente_id}</h1>
            <p>
              <b>Nome:</b> RENATO PARDO
            </p>
            <p>
              {' '}
              <b> UH:</b> 0605
            </p>
            <p>
              {' '}
              <b>Reserva: </b> 223332455
            </p>
            <p>
              {' '}
              <b>Entrada:</b> 01/05/2019
            </p>
            <p>
              {' '}
              <b>Saida:</b> 31/12/2020
            </p>
          </div>

          <div className="div-extrato-table">
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Data/Hora</StyledTableCell>
                    <StyledTableCell>Descrição</StyledTableCell>
                    <StyledTableCell>Valor</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <StyledTableRow key={row.index}>
                      <StyledTableCell component="th" scope="row">
                        {row.date}
                      </StyledTableCell>
                      <StyledTableCell>{row.description}</StyledTableCell>
                      <StyledTableCell>{row.value}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div
            className="div-extrato-cliente-name"
            style={{ marginTop: '20px' }}
          >
            <p>
              {' '}
              <b>Total:</b> 389.75
            </p>
            <button
              className="btn-chackout"
              style={{ background: cor1, cursor: 'pointer' }}
            >
              Solicitar Checkout{' '}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
