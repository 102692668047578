import React, { useEffect } from 'react';
import { GlobalContext, GlobalStorage } from './GlobalContext';
import Rotas from './Rotas';
import './styles/global.css';
import { useTour, TourProvider } from '@reactour/tour';
import { steps } from './Components/Tour/Steps';
import ReactGA from 'react-ga';
import { CartProvider } from './CartContext';

ReactGA.initialize('UA-236480625-2');
ReactGA.pageview(window.location.pathname + window.location.search);

const App = () => {
  const [ultrapassouLimite, setUltrapassouLimite] = React.useState(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-476Z82DKZ1`;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-476Z82DKZ1');
  }, []);

  React.useEffect(() => {
    const storedUltrapassouLimite = localStorage.getItem('ultrapassou_limite');
    if (storedUltrapassouLimite !== null) {
      setUltrapassouLimite(storedUltrapassouLimite === 'true');
    }
  }, []);

  const setDarkMode = () => {
    document.querySelector('*').setAttribute('data-theme', 'dark');
    localStorage.setItem('selectedTheme', 'dark');
  };
  const setLightMode = () => {
    document.querySelector('*').setAttribute('data-theme', 'light');
    localStorage.setItem('selectedTheme', 'light');
  };

  const selectedTheme = localStorage.getItem('selectedTheme');
  const cliente = React.useContext(GlobalContext);
  if (selectedTheme === 'dark') {
    setDarkMode();
  }

  const radius = 8;

  // Condição para exibir a mensagem "tempo excedido"
  if (ultrapassouLimite === true) {
    return <div>Tempo excedido</div>;
  }

  // Caso contrário, exibe o app
  return (
    <GlobalStorage>
      <TourProvider
        steps={steps}
        onClickMask={({ setCurrentStep, currentStep, steps, setIsOpen }) => {
          if (steps) {
            if (currentStep === steps.length - 1) {
              setIsOpen(false);
            }
            setCurrentStep((s) => (s === steps.length - 1 ? 0 : s + 1));
          }
        }}
        styles={{
          popover: (base) => ({
            ...base,
            '--reactour-accent': '#ef5a3d',
            borderRadius: radius,
            margin: 10,
          }),
          maskArea: (base) => ({ ...base, rx: radius, color: 'red' }),
          badge: (base) => ({ ...base }),
          controls: (base) => ({ ...base, marginTop: 30 }),
          close: (base) => ({
            ...base,
            top: 10,
            right: 10,
            width: 12,
            color: '#ef5a3d',
          }),
        }}
      >
        <CartProvider>
          <Rotas />
        </CartProvider>
      </TourProvider>
    </GlobalStorage>
  );
};

export default App;
