import React, { useState, useEffect } from 'react';
import './style.css';
import Box from '@mui/material/Box';
import { HeaderFacilities } from '../../../Components/HeaderFacilities';
import { useParams, useLocation } from 'react-router-dom';
import { Link } from 'react-scroll';
import Cookies from 'universal-cookie';
import MapIcon from '@mui/icons-material/Map';
import InstagramIcon from '@mui/icons-material/Instagram';
import LanguageIcon from '@mui/icons-material/Language';
import CallIcon from '@mui/icons-material/Call';
const { REACT_APP_URL_API } = process.env;

const style = {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'var(--background)',
  border: '2px solid var(--title)',
  boxShadow: 24,
  p: 4,
};

const Concierge = () => {
  const [data, setData] = useState(null);
  const [concierge, setConcierge] = useState(null);
  const { cliente_id } = useParams();
  const location = useLocation();
  const [error, setError] = useState(null);

  // Extrai parâmetros da URL
  const queryParams = new URLSearchParams(location.search);
  const apartamento = queryParams.get('x');
  const cliente_url = queryParams.get('id_cliente');
  const session_control = queryParams.get('session_control');

  // Recupera valores de ip_address e session do localStorage
  const userIp = localStorage.getItem('ip_address');
  const session = localStorage.getItem('session');

  const userLanguage = navigator.language || navigator.userLanguage; // Obtém o idioma do navegador

  const raw = JSON.stringify({
    cliente_id: cliente_id,
    ip_address: userIp,
    language: userLanguage,
    session: session,
  });

  const cookies = new Cookies();
  const token = cookies.get('csrftoken2');

  const headers = new Headers({
    'Content-Type': 'application/json',
    'X-CSRFToken': token,
  });

  useEffect(() => {
    window
      .fetch(
        `${REACT_APP_URL_API}/apiv2/index/?x=${apartamento}&id_cliente=${cliente_url}&session_control=${session_control}`,
        {
          method: 'POST',
          credentials: 'omit',

          headers: headers,
          body: raw,
        },
      )
      .then((response) => response.json())
      .then((json) => setData(json))
      .catch((error) => {
        setError(error);
      });
  }, [cliente_id, apartamento, cliente_url, session_control]);

  useEffect(() => {
    window
      .fetch(
        `${REACT_APP_URL_API}/apiv2/concierge/?x=${apartamento}&id_cliente=${cliente_url}&session_control=${session_control}`,
        {
          method: 'POST',
          credentials: 'omit',

          headers: headers,
          body: raw,
        },
      )
      .then((response) => response.json())
      .then((json) => setConcierge(json))
      .catch((error) => {
        setError(error);
      });
  }, [cliente_id, apartamento, cliente_url, session_control]);

  if (data === null) return null;
  if (concierge === null) return null;

  const cor1 = data.cliente[0]?.cor_custom;
  const cor2 = data.cliente[0]?.cor_1;

  return (
    <div>
      <HeaderFacilities />

      <div className="categorias categorias-cardapio">
        <ul>
          {concierge.concierge.map((category) => (
            <li key={category.id}>
              <Link
                className={category.nome_item.toLowerCase().replace(/ /g, '-')}
                to={category.nome_item.toLowerCase().replace(/ /g, '-')}
                spy={true}
                smooth={true}
                duration={500}
                offset={-99}
                activeClass="ActiveLink"
              >
                {category.nome_item}
              </Link>
            </li>
          ))}
        </ul>
      </div>

      <Box
        sx={{
          maxWidth: '930px',
          margin: '120px auto',
          left: '1%',
          right: '1%',
          bgcolor: 'var(--background-main)',
        }}
      >
        {concierge.concierge.map((category) => (
          <div
            key={category.id}
            className={category.nome_item.toLowerCase().replace(/ /g, '-')}
            name={category.nome_item.toLowerCase().replace(/ /g, '-')}
            id={category.nome_item.toLowerCase().replace(/ /g, '-')}
          >
            <div className="container-clube-voucher">
              <h2
                style={{
                  color: 'var(--secondary)',
                  fontSize: '16px',
                  fontWeight: 400,
                  padding: '0px 0px 10px 10px',
                }}
              >
                {category.nome_item}
              </h2>

              {(category.item_concierge || []).map((item) => (
                <div key={item.id}>
                  <div className="box container-card-cardapio">
                    <div className="concierge_container">
                      {/* <div>
                        <img
                          src="https://via.placeholder.com/150"  
                          alt=""
                        />
                      </div> */}

                      <div className="counteudo-cardapio div-conteudo-concierge">
                        <p>
                          <b>{item.nome}</b>
                        </p>
                        <p>{item.descricao}</p>
                        {item.horario_funcionamento && (
                          <p> Aberto das {item.horario_funcionamento}</p>
                        )}
                        <div className="div-btn-concierge">
                          {item.api_mapa && (
                            <a
                              href={item.api_mapa}
                              target="blank"
                              style={{
                                background:
                                  cor2 === 'outra'
                                    ? cor2
                                    : {
                                        'azul-escuro': '#3d489b',
                                        azul: '#4892DF',
                                        verde: '#3BBF9F',
                                        cinza: '#A6B1B4',
                                        'cinza-solido': '#f2f2f2',
                                        vermelho: '#FF5E5E',
                                        laranja: '#FF9A38',
                                        amarelo: '#FFFF00',
                                        roxo: '#800080',
                                        dourado: '#b99c56',
                                        'azul-marinho': '#1C7FBC',
                                        'azul-solido': '#315694',
                                        marrom: '#673C0D',
                                        branco: '#FFFFFF',
                                      }[cor2] || cor2,
                              }}
                            >
                              <MapIcon />
                            </a>
                          )}

                          {item.instagramLink && (
                            <a
                              href={item.instagramLink}
                              target="blank"
                              style={{
                                background:
                                  cor2 === 'outra'
                                    ? cor2
                                    : {
                                        'azul-escuro': '#3d489b',
                                        azul: '#4892DF',
                                        verde: '#3BBF9F',
                                        cinza: '#A6B1B4',
                                        'cinza-solido': '#f2f2f2',
                                        vermelho: '#FF5E5E',
                                        laranja: '#FF9A38',
                                        amarelo: '#FFFF00',
                                        roxo: '#800080',
                                        dourado: '#b99c56',
                                        'azul-marinho': '#1C7FBC',
                                        'azul-solido': '#315694',
                                        marrom: '#673C0D',
                                        branco: '#FFFFFF',
                                      }[cor2] || cor2,
                              }}
                            >
                              <InstagramIcon />
                            </a>
                          )}

                          {item.site && (
                            <a
                              href={item.site}
                              target="blank"
                              style={{
                                background:
                                  cor2 === 'outra'
                                    ? cor2
                                    : {
                                        'azul-escuro': '#3d489b',
                                        azul: '#4892DF',
                                        verde: '#3BBF9F',
                                        cinza: '#A6B1B4',
                                        'cinza-solido': '#f2f2f2',
                                        vermelho: '#FF5E5E',
                                        laranja: '#FF9A38',
                                        amarelo: '#FFFF00',
                                        roxo: '#800080',
                                        dourado: '#b99c56',
                                        'azul-marinho': '#1C7FBC',
                                        'azul-solido': '#315694',
                                        marrom: '#673C0D',
                                        branco: '#FFFFFF',
                                      }[cor2] || cor2,
                              }}
                            >
                              <LanguageIcon />
                            </a>
                          )}

                          {item.telefone && (
                            <a
                              href={item.telefone}
                              style={{
                                background:
                                  cor2 === 'outra'
                                    ? cor2
                                    : {
                                        'azul-escuro': '#3d489b',
                                        azul: '#4892DF',
                                        verde: '#3BBF9F',
                                        cinza: '#A6B1B4',
                                        'cinza-solido': '#f2f2f2',
                                        vermelho: '#FF5E5E',
                                        laranja: '#FF9A38',
                                        amarelo: '#FFFF00',
                                        roxo: '#800080',
                                        dourado: '#b99c56',
                                        'azul-marinho': '#1C7FBC',
                                        'azul-solido': '#315694',
                                        marrom: '#673C0D',
                                        branco: '#FFFFFF',
                                      }[cor2] || cor2,
                              }}
                            >
                              <CallIcon />
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </Box>
    </div>
  );
};

export default Concierge;
