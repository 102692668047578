// src/Components/HeaderCardapio.js
import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Badge from '@mui/material/Badge';
import { styled, alpha } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import RoomServiceIcon from '@mui/icons-material/RoomService';
import { IconContext } from 'react-icons';
import LogoClienteFacilities from '../LogoClienteFacilities';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import { useCart } from '../../CartContext';
import Cookies from 'universal-cookie';

const { REACT_APP_URL_API } = process.env;

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: '999',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export function HeaderCardapio({ searchTerm, setSearchTerm }) {
  const [social, setSocial] = useState(null);
  const { cliente_id } = useParams();
  const location = useLocation();
  const { cartItems } = useCart();
  const cliente = cliente_id;
  const queryParams = new URLSearchParams(location.search);
  const apartamento = queryParams.get('x');
  const cliente_url = queryParams.get('id_cliente');
  const session_control = queryParams.get('session_control');
  const userIp = localStorage.getItem('ip_address');
  const session = localStorage.getItem('session');
  let [carrinho, setCarrinho] = React.useState([]);
  const [error, setError] = useState(null);

  const userLanguage = navigator.language || navigator.userLanguage;

  useEffect(() => {
    const fetchData = async () => {
      const userIp = localStorage.getItem('ip_address');
      const session = localStorage.getItem('session');

      const cookies = new Cookies();
      const token = cookies.get('csrftoken2');

      const headers = new Headers({
        'Content-Type': 'application/json',
        'X-CSRFToken': token,
      });

      const body = JSON.stringify({
        cliente_id: cliente,
        ip_address: userIp,
        language: userLanguage,
        session: session,
      });

      try {
        const response = await fetch(
          `${REACT_APP_URL_API}/apiv2/index/?x=${apartamento}&id_cliente=${cliente_url}&session_control=${session_control}`,
          {
            method: 'POST',
            credentials: 'omit',
            headers,
            body,
          },
        );
        const result = await response.json();
        setSocial(result);
      } catch (error) {
        console.error('Erro ao obter os dados:', error);
      }
    };

    fetchData();
  }, [cliente, apartamento, cliente_url, session_control]);

  const cookies = new Cookies();
  const token = cookies.get('csrftoken2');

  const headers = new Headers({
    'Content-Type': 'application/json',
    'X-CSRFToken': token,
  });

  const body = JSON.stringify({
    cliente_id: cliente_id,
    ip_address: userIp,
    session: session,
  });

  useEffect(() => {
    window
      .fetch(
        `${REACT_APP_URL_API}/apiv2/cart/?x=${apartamento}&id_cliente=${cliente_url}&session_control=${session_control}`,
        {
          method: 'POST',
          headers: headers,
          body: body,
          credentials: 'omit',
        },
      )
      .then((response) => response.json())
      .then((json) => setCarrinho(json))
      .catch((error) => setError(error));
  }, [cliente_id, apartamento, cliente_url, session, session_control]);

  // Cálculo do total de itens e valor total
  const totalItens = carrinho.reduce((acc, item) => acc + item.quantidade, 0);
  const valorTotal = carrinho.reduce(
    (acc, item) => acc + item.quantidade * item.valor,
    0,
  );

  if (social === null) return null;
  if (carrinho === null) return null;

  const color = social.cliente[0]?.cor_1;
  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -3,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
      background:
        color === 'outra'
          ? color
          : {
              'azul-escuro': '#3d489b',
              azul: '#4892DF',
              verde: '#3BBF9F',
              cinza: '#A6B1B4',
              'cinza-solido': '#f2f2f2',
              vermelho: '#FF5E5E',
              laranja: '#FF9A38',
              amarelo: '#FFFF00',
              roxo: '#800080',
              dourado: '#b99c56',
              'azul-marinho': '#1C7FBC',
              'azul-solido': '#315694',
              marrom: '#673C0D',
              branco: '#FFFFFF',
            }[color] || color,
    },
  }));

  const mainURL = window.location.href;
  const mainUrlParam = mainURL.split('/');

  return (
    <header className="header">
      <div className="container">
        <button
          onClick={() => window.history.back()}
          className="btn-header-return"
        >
          <ArrowBackIosIcon />
        </button>
        <LogoClienteFacilities />

        {mainUrlParam[4] === 'cardapio' && mainUrlParam[5] !== 'tipo' ? (
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Buscar"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              inputProps={{ 'aria-label': 'search' }}
              style={{ height: '41px' }}
            />
          </Search>
        ) : null}

        <div className="menu-icons">
          <IconContext.Provider value={{ size: '26px' }}>
            <div>
              {/* <div className="card_room">
                <RoomServiceIcon />
                <span>9999</span>
              </div> */}
            </div>

            {social.cliente[0]?.pedido_menu === true && (
              <IconButton aria-label="cart">
                <a
                  href={`/${cliente_id}/carrinho/?x=${apartamento}&id_cliente=${cliente_url}&session_control=${session_control}`}
                >
                  <StyledBadge badgeContent={totalItens} color="secondary">
                    <ShoppingCartIcon />
                  </StyledBadge>
                </a>
              </IconButton>
            )}
          </IconContext.Provider>
        </div>
      </div>
    </header>
  );
}
