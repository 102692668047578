import './style.css';
import ReactGA from 'react-ga';
import { Modal } from '@mui/material';
import Stories from 'react-insta-stories';
import { useParams, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

const { REACT_APP_URL_API } = process.env;

const stories = [
  {
    url: 'https://images.pexels.com/photos/3494373/pexels-photo-3494373.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
    duration: 2000,
    header: {
      heading: 'Cusco Baudoso',
      profileImage:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVLakRiNJ_pidUtJfw3I4mLRkMtbY_gujv1t1Jm5A-cA&s',
    },
  },
  {
    url: 'https://s2.best-wallpaper.net/wallpaper/iphone/2109/Blue-sea-coast-sailboat-boat-trees-rocks_iphone_1080x1920.jpg',
    duration: 3000,
    header: {
      heading: 'Cusco Baudoso',
      profileImage:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVLakRiNJ_pidUtJfw3I4mLRkMtbY_gujv1t1Jm5A-cA&s',
    },
  },
  {
    url: 'https://player.vimeo.com/external/420568727.sd.mp4?s=bd4d6b75a5a1c960a222d3c9923574f70d122e0c&profile_id=165&oauth2_token_id=57447761',
    duration: 3000,
    type: 'video',
    header: {
      heading: 'Cusco Baudoso',
      profileImage:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVLakRiNJ_pidUtJfw3I4mLRkMtbY_gujv1t1Jm5A-cA&s',
    },
  },
];

const stories2 = [
  {
    url: 'https://uploaddeimagens.com.br/images/004/425/911/full/Access_Point_Wireless_Dual_Band_AX3600_Monta%CC%81vel_em_Teto_%283%29.png?1681231841',
    duration: 2000,
    header: {
      heading: 'Tp Link',
      profileImage:
        'https://www.baixesoft.com/wp-content/uploads/2021/03/TP-link-icone.png',
    },
  },
  {
    url: 'https://uploaddeimagens.com.br/images/004/425/908/full/Access_Point_Wireless_Dual_Band_AX3600_Monta%CC%81vel_em_Teto.png?1681231744',
    duration: 3000,
    header: {
      heading: 'Tp Link',
      profileImage:
        'https://www.baixesoft.com/wp-content/uploads/2021/03/TP-link-icone.png',
    },
  },
  {
    url: 'https://uploaddeimagens.com.br/images/004/425/915/full/Access_Point_Wireless_Dual_Band_AX3600_Monta%CC%81vel_em_Teto_%282%29.png?1681231884',
    duration: 3000,
    header: {
      heading: 'Tp Link',
      profileImage:
        'https://www.baixesoft.com/wp-content/uploads/2021/03/TP-link-icone.png',
    },
  },
  {
    url: 'https://uploaddeimagens.com.br/images/004/425/916/full/Access_Point_Wireless_Dual_Band_AX3600_Monta%CC%81vel_em_Teto_%281%29.png?1681231926',
    duration: 3000,
    header: {
      heading: 'Tp Link',
      profileImage:
        'https://www.baixesoft.com/wp-content/uploads/2021/03/TP-link-icone.png',
    },
  },
  {
    url: 'https://uploaddeimagens.com.br/images/004/425/924/full/Access_Point_Wireless_Dual_Band_AX3600_Monta%CC%81vel_em_Teto_%284%29.png?1681232556',
    duration: 3000,
    header: {
      heading: 'Tp Link',
      profileImage:
        'https://www.baixesoft.com/wp-content/uploads/2021/03/TP-link-icone.png',
    },
  },
  {
    url: 'https://uploaddeimagens.com.br/images/004/425/930/full/Access_Point_Wireless_Dual_Band_AX3600_Monta%CC%81vel_em_Teto_%285%29.png?1681232859',
    duration: 3000,
    header: {
      heading: 'Tp Link',
      profileImage:
        'https://www.baixesoft.com/wp-content/uploads/2021/03/TP-link-icone.png',
    },
  },
];

export function Story({ story, action }) {
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  const handleOpen = (category, action, label) => {
    setOpen(true);
    console.log('GA event:', category, ':', action, ':', label);
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    });
  };

  const handleOpen2 = (category, action, label) => {
    setOpen2(true);
    console.log('GA event:', category, ':', action, ':', label);
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    });
  };

  const handleClose = () => setOpen(false);
  const handleClose2 = () => setOpen2(false);

  const [data, setData] = useState(null);
  const { cliente_id } = useParams();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const apartamento = queryParams.get('x');
  const cliente_url = queryParams.get('id_cliente');
  const session_control = queryParams.get('session_control');

  const session = localStorage.getItem('session');

  useEffect(() => {
    const fetchIpAndSendData = async () => {
      try {
        const ipResponse = await fetch('https://api.ipify.org?format=json');
        const ipData = await ipResponse.json();
        const userIp = ipData.ip;

        const body = JSON.stringify({
          cliente_id: cliente_id,
          ip_address: userIp,
          session: session,
          session_control: session_control,
        });

        const response = await fetch(
          `${REACT_APP_URL_API}/apiv2/index/?x=${apartamento}&id_cliente=${cliente_url}&session_control=${session_control}`,
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: body,
          },
        );

        const json = await response.json();
        setData(json);
      } catch (error) {
        console.error('Erro ao obter o IP do usuário ou enviar dados:', error);
      }
    };

    fetchIpAndSendData();
  }, [cliente_id, apartamento, cliente_url, session, session_control]);

  if (data === null) return null;

  return (
    <div className="container">
      <div className="user-elements">
        <div
          style={{
            background: `  ${data.cliente[0]?.cor_custom}  `,
          }}
        >
          <img
            className="image-user-story"
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVLakRiNJ_pidUtJfw3I4mLRkMtbY_gujv1t1Jm5A-cA&s"
            onClick={handleOpen.bind(
              this,
              'Story',
              'alguem abriu',
              'Cusco Baudoso',
            )}
          />
        </div>
        <span>Cusco B.</span>
      </div>

      <div className="user-elements">
        <div
          style={{
            background: `  ${data.cliente[0]?.cor_custom}  `,
          }}
        >
          <img
            className="image-user-story"
            src="https://www.baixesoft.com/wp-content/uploads/2021/03/TP-link-icone.png"
            onClick={handleOpen2.bind(this, 'Story', 'alguem abriu', 'Tp Link')}
          />
        </div>
        <span>Tp Link</span>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ marginTop: '2px' }}
      >
        <div className="div-story">
          <button className="btn-fechar-story" onClick={handleClose}>
            X
          </button>
          <Stories stories={stories} defaultInterval={1500} />
        </div>
      </Modal>

      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ marginTop: '2px' }}
      >
        <div className="div-story">
          <button className="btn-fechar-story" onClick={handleClose2}>
            X
          </button>
          <Stories stories={stories2} defaultInterval={1500} />
        </div>
      </Modal>
    </div>
  );
}
