import React, { createContext, useContext, useState } from 'react';

const CartContext = createContext();
export const GlobalContext = React.createContext();
export const GlobalStorage = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);

  const addItemToCart = (item) => {
    setCartItems([...cartItems, item]);
  };

  const removeItemFromCart = (item) => {
    setCartItems(cartItems.filter((cartItem) => cartItem.id !== item.id));
  };

  const value = {
    cartItems,
    addItemToCart,
    removeItemFromCart,
  };

  return (
    <div>
      <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
    </div>
  );
};
