import React from 'react';
import './styles/global.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NotFound from './Screens/NotFound';
import { GlobalContext, GlobalStorage } from './GlobalContext';
import { useParams } from 'react-router-dom';
import FacilitiesHome from './Screens/Facilities/FacilitiesHome';
import FacilitiesCardapio from './Screens/Facilities/FacilitiesCardapio';
import CardapioItem from './Screens/Facilities/CardapioItem';
import Carrinho from './Screens/Facilities/Carrinho';
import Concierge from './Screens/Facilities/Concierge';
import Diretorio from './Screens/Facilities/Diretorio';
import { Extrato } from './Screens/Facilities/Extrato';
import TipoRefeicao from './Screens/Facilities/TipoRefeicao';
import Sucesso from './Screens/Facilities/Sucesso';

const Rotas = () => {
  return (
    <Routes>
      <Route path="/:cliente_id/:partner_id/*" element={<FacilitiesHome />} />
      <Route path="/:cliente_id/*" element={<FacilitiesHome />} />
      <Route path="/:cliente_id/global" element={<GlobalStorage />} />
      {/* <Route path="/:cliente_id/facilities" element={<FacilitiesHome />} /> */}
      <Route path="/:cliente_id/cardapio/*" element={<FacilitiesCardapio />} />
      <Route
        path="/:cliente_id/cardapio/:tipo_id/*"
        element={<FacilitiesCardapio />}
      />
      <Route path="/:cliente_id/cardapio/tipo/*" element={<TipoRefeicao />} />
      <Route
        path="/:cliente_id/cardapioitem/:item_id/*"
        element={<CardapioItem />}
      />
      <Route path="/cardapio/:cliente_id/:item_id/*" component={CardapioItem} />
      <Route path="/carrinho" component={Carrinho} />
      <Route path="/:cliente_id/carrinho/*" element={<Carrinho />} />
      <Route path="/:cliente_id/concierge" element={<Concierge />} />
      <Route path="/:cliente_id/diretorio" element={<Diretorio />} />
      <Route path="/:cliente_id/extrato" element={<Extrato />} />
      <Route path="/:cliente_id/sucesso/*" element={<Sucesso />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Rotas;
