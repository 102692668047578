import React, { useState, useEffect } from 'react';
import './style.css';
import { HeaderCardapio } from '../../../Components/HeaderCardapio';
import ContadorCardapio from '../../../Components/ContadorCardapio';
import Cookies from 'universal-cookie';
import { Link, useParams, useLocation } from 'react-router-dom';
import { useCart } from '../../../CartContext';

const { REACT_APP_URL_API } = process.env;

const CardapioItem = () => {
  const [data, setData] = useState(null);
  const [menu, setMenu] = useState(null);
  const { cliente_id, item_id } = useParams();
  const [error, setError] = useState(null);
  const [quantidade, setQuantidade] = useState(1);
  const [adicionaisSelecionados, setAdicionaisSelecionados] = useState([]);
  const [isAdded, setIsAdded] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  const { addItemToCart } = useCart();

  const cookies = new Cookies();
  const token = cookies.get('csrftoken2');

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const apartamento = queryParams.get('x');
  const cliente_url = queryParams.get('id_cliente');
  const session_control = queryParams.get('session_control');

  const session = localStorage.getItem('session');
  const userIp = localStorage.getItem('ip_address');

  const headers = new Headers({
    'Content-Type': 'application/json',
    'X-CSRFToken': token,
  });

  const findItem = (menu) => {
    for (const tipo of menu.tipo_refeicao) {
      for (const categoria of tipo.categoria_cardapio) {
        const item = categoria.item_cardapio.find((item) => item.id === itemId);
        if (item) return item;
      }

      if (tipo.item_cardapio_sem_cat) {
        const itemSemCat = tipo.item_cardapio_sem_cat.find(
          (item) => item.id === itemId,
        );
        if (itemSemCat) return itemSemCat;
      }
    }

    return null;
  };

  useEffect(() => {
    window
      .fetch(
        `${REACT_APP_URL_API}/apiv2/index/?x=${apartamento}&id_cliente=${cliente_url}&session_control=${session_control}`,
        {
          method: 'POST',
          credentials: 'omit',
          headers: headers,
          body: JSON.stringify({
            cliente_id: cliente_id,
            ip_address: userIp,
            session: session,
          }),
        },
      )
      .then((response) => response.json())
      .then((json) => setData(json))
      .catch((error) => setError(error));
  }, [cliente_id, apartamento, cliente_url, session, session_control]);

  useEffect(() => {
    window
      .fetch(
        `${REACT_APP_URL_API}/apiv2/menu/?x=${apartamento}&id_cliente=${cliente_url}&session_control=${session_control}`,
        {
          method: 'POST',
          credentials: 'omit',
          headers: headers,
          body: JSON.stringify({
            cliente_id: cliente_id,
            ip_address: userIp,
            session: session,
          }),
        },
      )
      .then((response) => response.json())
      .then((json) => setMenu(json))
      .catch((error) => setError(error));
  }, [cliente_id, apartamento, cliente_url, session, session_control]);

  if (data === null || menu === null) return null;

  const itemId = parseInt(item_id, 10);
  const item = findItem(menu);
  const cor2 = data.cliente[0]?.cor_1;
  const cor_letra = data.cliente[0]?.cor_letra;

  if (!item) {
    return <div>Item não encontrado</div>;
  }

  const handleQuantidadeChange = (novaQuantidade) => {
    setQuantidade(novaQuantidade);
  };

  const handleAdicionaisChange = (adicionalId) => {
    setAdicionaisSelecionados((prevAdicionais) =>
      prevAdicionais.includes(adicionalId)
        ? prevAdicionais.filter((id) => id !== adicionalId)
        : [...prevAdicionais, adicionalId],
    );
  };

  const handleAddToCart = () => {
    setIsAdding(true); // Iniciar animação de crescimento

    const payload = {
      cliente_id: cliente_id,
      ip_address: userIp,
      session: session,
      operation: 'add',
      items: [
        {
          id: itemId,
          quantity: quantidade,
          additional_items: adicionaisSelecionados,
        },
      ],
    };

    fetch(
      `${REACT_APP_URL_API}/apiv2/cart/update/?x=${apartamento}&id_cliente=${cliente_url}&session_control=${session_control}`,
      {
        method: 'POST',
        credentials: 'omit',
        headers: headers,
        body: JSON.stringify(payload),
      },
    )
      .then((response) => response.json())
      .then((data) => {
        console.log('Item adicionado ao carrinho:', data);
        addItemToCart(quantidade);

        // Alterar para o estado "adicionado"
        setIsAdded(true);

        // Após 2 segundos, voltar ao estado original do botão
        setTimeout(() => {
          setIsAdded(false);
          setIsAdding(false); // Parar animação de crescimento

          // Recarregar a página após a adição do item
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        console.error('Erro ao adicionar item ao carrinho:', error);
        setIsAdded(false);
        setIsAdding(false); // Parar animação em caso de erro
      });
  };

  const renderGrupoItems = (grupo) => {
    return grupo.items.map((item, index) => (
      <div key={index} className="div-opcionais">
        <input
          type="checkbox"
          value={item[0]} // Usar o ID do item adicional
          onChange={() => handleAdicionaisChange(item[1])}
        />
        <p>{item[1]}</p>
      </div>
    ));
  };

  return (
    <div>
      <HeaderCardapio />
      <div className="MainGrid">
        <div className="container-cardapio-item">
          <div className="container-img-text">
            {item.img && <img src={`${REACT_APP_URL_API}` + item.img} />}
            <h3 style={{ fontSize: '18px' }}>{item.nome} </h3>
            <div style={{ marginTop: '10px' }}>
              {/* <p>R$ {item.valor}</p> */}
            </div>
            <p style={{ padding: '0px 20px 20px 20px' }}>{item.descricao}</p>
          </div>

          {item.habilita_pedido ? (
            <div>
              {item.grupo_item_adicional && (
                <>
                  <div className="div-text-opcionais">
                    <h1>{item.grupo_item_adicional.group_name}</h1>
                    <p>{item.grupo_item_adicional.group_description}</p>
                  </div>
                  <hr />
                  <div className="div-opcionais">
                    <div>{renderGrupoItems(item.grupo_item_adicional)}</div>
                  </div>
                </>
              )}

              <div className="div-container-btn-cardapio">
                <div className="div-button-cardapio">
                  <div>
                    <ContadorCardapio
                      quantidade={quantidade}
                      onQuantidadeChange={handleQuantidadeChange}
                    />
                  </div>
                  <button
                    className={`btn-cardapio-add ${isAdded ? 'added' : ''} ${
                      isAdding ? 'adding' : ''
                    }`}
                    style={{
                      background: isAdded
                        ? '#3BBF9F'
                        : cor2 === 'outra'
                        ? cor2
                        : {
                            azul: '#4892DF',
                            verde: '#3BBF9F',
                            cinza: '#A6B1B4',
                            'cinza-solido': '#f2f2f2',
                            'azul-escuro': '#3d489b',
                            vermelho: '#FF5E5E',
                            laranja: '#FF9A38',
                            amarelo: '#FFFF00',
                            roxo: '#800080',
                            dourado: '#b99c56',
                            'azul-marinho': '#1C7FBC',
                            'azul-solido': '#315694',
                            marrom: '#673C0D',
                            branco: '#FFFFFF',
                          }[cor2] || cor2,
                      color: isAdded ? '#fff' : cor_letra,
                    }}
                    onClick={handleAddToCart}
                  >
                    {isAdded ? (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-check"
                        >
                          <polyline points="20 6 9 17 4 12"></polyline>
                        </svg>
                        Adicionado
                      </>
                    ) : (
                      `Adicionar: R$${(item.valor * quantidade).toLocaleString(
                        'pt-BR',
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        },
                      )}`
                    )}
                  </button>
                </div>

                {/* <div className="div-btn-carrinho">
                  <Link
                    to={`/${cliente_id}/carrinho/?x=${apartamento}&id_cliente=${cliente_url}&session_control=${session_control}`}
                    className="btn-ver-carrinho"
                    style={{
                      background:
                        cor2 === 'outra'
                          ? cor2
                          : {
                              'azul-escuro': '#3d489b',
                              azul: '#4892DF',
                              verde: '#3BBF9F',
                              cinza: '#A6B1B4',
                              'cinza-solido': '#f2f2f2',
                              vermelho: '#FF5E5E',
                              laranja: '#FF9A38',
                              amarelo: '#FFFF00',
                              roxo: '#800080',
                              dourado: '#b99c56',
                              'azul-marinho': '#1C7FBC',
                              'azul-solido': '#315694',
                              marrom: '#673C0D',
                              branco: '#FFFFFF',
                            }[cor2] || cor2,
                    }}
                  >
                    Ver Carrinho
                  </Link>
                </div> */}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CardapioItem;
