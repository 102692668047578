import React, { useEffect, useState } from 'react';
import './style.css';
import { HeaderCardapio } from '../../../Components/HeaderCardapio';
import { Link, useParams, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';

const { REACT_APP_URL_API } = process.env;

const TipoRefeicao = () => {
  const [data, setData] = useState(null);
  const [menu, setMenu] = useState(null);
  const { cliente_id } = useParams();
  const location = useLocation();

  const cliente = cliente_id;

  // Extrai parâmetros da URL
  const queryParams = new URLSearchParams(location.search);
  const apartamento = queryParams.get('x');
  const cliente_url = queryParams.get('id_cliente');
  const session_control = queryParams.get('session_control');

  useEffect(() => {
    const fetchData = async () => {
      const userIp = localStorage.getItem('ip_address');
      const session = localStorage.getItem('session');

      const cookies = new Cookies();
      const token = cookies.get('csrftoken2');

      const headers = new Headers({
        'Content-Type': 'application/json',
        'X-CSRFToken': token,
      });

      const userLanguage = navigator.language || navigator.userLanguage; // Obtém o idioma do navegador

      const body = JSON.stringify({
        cliente_id: cliente,
        ip_address: userIp,
        language: userLanguage,
        session: session,
      });

      try {
        const response = await fetch(
          `${REACT_APP_URL_API}/apiv2/index/?x=${apartamento}&id_cliente=${cliente_url}&session_control=${session_control}`,
          {
            method: 'POST',
            credentials: 'omit',
            headers,
            body,
          },
        );
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error('Erro ao obter os dados:', error);
      }
    };

    fetchData();
  }, [cliente, apartamento, cliente_url, session_control]);

  useEffect(() => {
    const fetchMenu = async () => {
      const userIp = localStorage.getItem('ip_address');
      const session = localStorage.getItem('session');

      const cookies = new Cookies();
      const token = cookies.get('csrftoken');

      const headers = new Headers({
        'Content-Type': 'application/json',
        'X-CSRFToken': token,
      });

      const body = JSON.stringify({
        cliente_id: cliente,
        ip_address: userIp,
        session: session,
      });

      try {
        const response = await fetch(
          `${REACT_APP_URL_API}/apiv2/menu/?x=${apartamento}&id_cliente=${cliente_url}&session_control=${session_control}`,
          {
            method: 'POST',
            credentials: 'omit',
            headers,
            body,
          },
        );
        const result = await response.json();
        setMenu(result);
      } catch (error) {
        console.error('Erro ao obter o menu:', error);
      }
    };

    fetchMenu();
  }, [cliente, apartamento, cliente_url, session_control]);

  if (data === null || menu === null) return null;

  const imageUrls = [
    'https://content6.flowwow-images.com/data/blog/53/1703008685_20432053.jpg',
    'https://img.freepik.com/fotos-gratis/ensopado-de-carne-recem-cozido-com-arroz-e-vegetais-generative-ai_188544-8342.jpg',
    'https://alpha-cms-content-prod.s3.us-east-1.amazonaws.com/blog/jantar_romantico_em_casa_com_vinho_37352a5373.jpg',
    'https://s2.glbimg.com/1V59kbJFGYkm2BJOZbD2SVddZHU=/620x413/smart/e.glbimg.com/og/ed/f/original/2021/06/17/3-bebidas-alcoolicas-para-conhecer-e-presentear-2.jpg',
    'https://cdn.deliway.com.br/blog/base/47a/9f9/e37/tabua-quente-bar.jpg',
    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLYLVEWoGuN-rSM6uQpTfIxZzj8UqpXPYveA&s',
    'https://img.quizur.com/f/img64bc7e652991e5.77943476.jpg?lastEdited=1690074727',
    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9XiSl2lCtRrQytvnktsCRTtwIV9Mo3NdQww&s',
  ];

  return (
    <div>
      <HeaderCardapio />

      <div className="MainGrid">
        <div className="firstColumn">
          <div
            className="box"
            style={{
              margin: '10px auto',
              border: 'none',
              background: 'var(--background-main)',
            }}
          >
            <p style={{ textAlign: 'center' }}>Escolha um item</p>

            <div className="div_tipo_grid">
              <div
                className="container__btn_facilities div__btn__flex"
                style={{ marginTop: '20px' }}
              >
                {menu.tipo_refeicao?.map((tipo, index) => (
                  <div key={tipo.id}>
                    <Link
                      to={`/${cliente_id}/cardapio/${tipo.id}/?x=${apartamento}&id_cliente=${cliente_url}&session_control=${session_control}`}
                      style={{
                        backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)),url(${
                          imageUrls[index % imageUrls.length]
                        })`,
                      }}
                      className="btn_facilities btn_facilities_tipo_refeicao"
                    >
                      {tipo.nome}
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TipoRefeicao;
