import React from 'react';
import { HeaderFacilities } from '../../../Components/HeaderFacilities';
import { Story } from '../../../Components/Story';
import Botaofacilities from '../../../Components/BotaoFacilities';

const FacilitiesHome = () => {
  return (
    <div>
      <HeaderFacilities />
      <div className="MainGrid">
        <div className="firstColumn">
          {/* <div className="box">
            {' '}
            <Story />{' '}
          </div> */}

          <div
            className="box"
            style={{
              margin: '10px auto',
              border: 'none',
              background: 'var(--background-main)',
            }}
          >
            <Botaofacilities />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FacilitiesHome;
