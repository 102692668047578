import React, { useState, useEffect } from 'react';
import './style.css';
import { HeaderCardapio } from '../../../Components/HeaderCardapio';
import ContadorCarrinho from '../../../Components/ContadorCarrinho';
import Cookies from 'universal-cookie';
import { useParams, useLocation } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { useCart } from '../../../CartContext';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';

const { REACT_APP_URL_API } = process.env;

const style = {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'var(--background)',
  border: '2px solid var(--title)',
  boxShadow: 24,
  p: 4,
};

const Carrinho = () => {
  let [data, setData] = React.useState(null);
  let [carrinho, setCarrinho] = React.useState([]);
  const { cliente_id, item_id } = useParams();
  const location = useLocation();
  const [error, setError] = useState(null);
  const itemId = parseInt(item_id, 10);
  const [abrir, setAbrir] = useState(false);
  const [local, setLocal] = useState(''); // Estado para armazenar o número do local
  const [internalOrder, setInternalOrder] = useState('12345'); // Pode ser gerado dinamicamente se necessário

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [quantidade, setQuantidade] = useState(1);
  const [adicionaisSelecionados, setAdicionaisSelecionados] = useState([]);
  const { addItemToCart } = useCart();

  const userIp = localStorage.getItem('ip_address');
  const session = localStorage.getItem('session');

  const queryParams = new URLSearchParams(location.search);
  const apartamento = queryParams.get('x');
  const cliente_url = queryParams.get('id_cliente');
  const session_control = queryParams.get('session_control');

  const cookies = new Cookies();
  const token = cookies.get('csrftoken2');
  const [itensRemovidos, setItensRemovidos] = useState([]); // Para controlar itens removidos

  const [showSuccessMessage, setShowSuccessMessage] = useState(false); // Para mostrar a mensagem de sucesso

  const userLanguage = navigator.language || navigator.userLanguage; // Obtém o idioma do navegador

  const headers = new Headers({
    'Content-Type': 'application/json',
    'X-CSRFToken': token,
  });

  const body = JSON.stringify({
    cliente_id: cliente_id,
    ip_address: userIp,
    language: userLanguage,
    session: session,
  });

  useEffect(() => {
    window
      .fetch(
        `${REACT_APP_URL_API}/apiv2/index/?x=${apartamento}&id_cliente=${cliente_url}&session_control=${session_control}`,
        {
          method: 'POST',
          headers: headers,
          body: body,
          credentials: 'omit',
        },
      )
      .then((response) => response.json())
      .then((json) => setData(json))
      .catch((error) => setError(error));
  }, [cliente_id, apartamento, cliente_url, session, session_control]);

  useEffect(() => {
    window
      .fetch(
        `${REACT_APP_URL_API}/apiv2/cart/?x=${apartamento}&id_cliente=${cliente_url}&session_control=${session_control}`,
        {
          method: 'POST',
          headers: headers,
          body: body,
          credentials: 'omit',
        },
      )
      .then((response) => response.json())
      .then((json) => setCarrinho(json))
      .catch((error) => setError(error));
  }, [cliente_id, apartamento, cliente_url, session, session_control]);

  if (data === null) return null;
  if (carrinho === null) return null;

  // Cálculo do total de itens e valor total
  const totalItens = carrinho.reduce((acc, item) => acc + item.quantidade, 0);
  const valorTotal = carrinho.reduce(
    (acc, item) => acc + item.quantidade * item.valor,
    0,
  );

  const incrementarQuantidade = (index) => {
    setCarrinho((prevCarrinho) => {
      const novoCarrinho = [...prevCarrinho];

      const itemId = novoCarrinho[index].id;

      novoCarrinho[index].quantidade += 1;

      handleAddToCart(
        itemId, // Agora, itemId é o ID do item selecionado corretamente
        novoCarrinho[index].quantidade, // Quantidade atualizada
        'update', // Usamos a operação 'add' para indicar que estamos incrementando
      );

      return novoCarrinho;
    });
  };

  // Função para decrementar a quantidade do item

  const decrementarQuantidade = (index) => {
    setCarrinho((prevCarrinho) => {
      const novoCarrinho = [...prevCarrinho];

      const itemId = novoCarrinho[index].id;

      // Decrementa a quantidade do item
      novoCarrinho[index].quantidade -= 1;

      // Se a quantidade chegar a zero, remover o item do carrinho
      if (novoCarrinho[index].quantidade === 0) {
        deleteCarrinho(index); // Chama a função para remover o item
      } else {
        // Caso contrário, apenas atualiza a quantidade
        handleAddToCart(
          itemId,
          novoCarrinho[index].quantidade,
          'update', // Usamos a operação 'update' para indicar que estamos atualizando
        );
      }

      return novoCarrinho;
    });
  };

  const deleteCarrinho = (index) => {
    setCarrinho((prevCarrinho) => {
      const novoCarrinho = [...prevCarrinho];

      const itemId = novoCarrinho[index].id;

      novoCarrinho[index].quantidade -= 1;

      handleAddToCart(itemId, novoCarrinho[index].quantidade, 'remove');

      return novoCarrinho;
    });

    // Adiciona o índice ao estado de itens removidos
    setItensRemovidos((prevState) => [...prevState, index]);

    // Atualizar a página após 1 segundo
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const cor1 = data[0]?.response.cor_custom;
  const cor2 = data.cliente[0]?.cor_1;
  const cor_letra = data.cliente[0]?.cor_letra;

  const handleQuantidadeChange = (novaQuantidade) => {
    setQuantidade(novaQuantidade);
  };

  const handleAdicionaisChange = (adicionalId) => {
    setAdicionaisSelecionados((prevAdicionais) =>
      prevAdicionais.includes(adicionalId)
        ? prevAdicionais.filter((id) => id !== adicionalId)
        : [...prevAdicionais, adicionalId],
    );
  };

  const handleAddToCart = (itemId, quantidade, operation = 'update') => {
    const payload = {
      cliente_id: cliente_id,
      ip_address: userIp,
      session: session,
      operation: operation,
      items: [
        {
          id: itemId,
          quantity: quantidade,
          additional_items: adicionaisSelecionados, // Adicionais, se houver
        },
      ],
    };

    fetch(
      `${REACT_APP_URL_API}/apiv2/cart/update/?x=${apartamento}&id_cliente=${cliente_url}&session_control=${session_control}`,
      {
        method: 'POST',
        credentials: 'omit',
        headers: headers,
        body: JSON.stringify(payload), // Envia o payload com o item e a quantidade
      },
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(
          `Item ${
            operation === 'update' ? 'adicionado' : 'removido'
          } ao carrinho:`,
          data,
        );
        addItemToCart(quantidade); // Atualiza o contexto do carrinho se necessário
      })
      .catch((error) => {
        console.error(
          `Erro ao ${
            operation === 'update' ? 'adicionar' : 'remover'
          } item do carrinho:`,
          error,
        );
      });
  };

  const handleLocalChange = (event) => {
    setLocal(event.target.value);
  };

  const handleFinalizeOrder = () => {
    const c = queryParams.get('c'); // Extrai o valor de 'c' da URL

    if (c === 'true') {
      // Se 'c' for igual a 'true', abre o modal
      handleOpen();
    } else {
      // Caso contrário, finaliza o pedido normalmente
      const payload = {
        cliente_id: cliente_id,
        ip_address: userIp,
        session: session,
        operation: 'finish',
      };

      fetch(
        `${REACT_APP_URL_API}/apiv2/cart/update/?x=${apartamento}&id_cliente=${cliente_url}&session_control=${session_control}`,
        {
          method: 'POST',
          credentials: 'omit',
          headers: headers,
          body: JSON.stringify(payload),
        },
      )
        .then((response) => response.json())
        .then((data) => {
          // Mostrar a mensagem de sucesso
          setShowSuccessMessage(true);

          // Redirecionar para o link de sucesso após 1 segundo
          setTimeout(() => {
            setShowSuccessMessage(false); // Ocultar a mensagem após 1 segundo

            // Redirecionar para a página de sucesso
            window.location.href = `/${cliente_id}/sucesso/?x=${apartamento}&id_cliente=${cliente_url}&session_control=${session_control}`;
          }, 1500);
        })
        .catch((error) => {
          console.error('Erro ao finalizar pedido:', error);
        });
    }
  };

  // Função para renderizar os itens adicionais
  const renderAdicionais = (adicionais) => {
    return adicionais.map((item, index) => (
      <div key={index} className="div-opcionais">
        <input
          type="checkbox"
          value={item[0]} // Usar o ID do item adicional
          onChange={() => handleAdicionaisChange(item[0])}
        />
        <p>{item[1]}</p>
      </div>
    ));
  };

  const handleModalFinalizeOrder = () => {
    // Verifica se o campo local está preenchido
    if (local) {
      const payload = {
        cliente_id: cliente_id,
        ip_address: userIp,
        session: session,
        operation: 'finish',
        local: local, // Adiciona o campo local ao payload
        internal_order: internalOrder, // Adiciona o campo internal_order ao payload
      };

      fetch(
        `${REACT_APP_URL_API}/apiv2/cart/update/?x=${apartamento}&id_cliente=${cliente_url}&session_control=${session_control}`,
        {
          method: 'POST',
          credentials: 'omit',
          headers: headers,
          body: JSON.stringify(payload),
        },
      )
        .then((response) => response.json())
        .then((data) => {
          // Redirecionar ou mostrar mensagem de sucesso
          setShowSuccessMessage(true);

          setTimeout(() => {
            setShowSuccessMessage(false);
            window.location.href = `/${cliente_id}/sucesso/?x=${apartamento}&id_cliente=${cliente_url}&session_control=${session_control}`;
          }, 1500);
        })
        .catch((error) => {
          console.error('Erro ao finalizar pedido:', error);
        });
    } else {
      alert('Por favor, insira o número do local.');
    }
  };

  return (
    <div>
      <HeaderCardapio />

      <div className="MainGrid">
        <div className="container_checkout">
          <h1>ITENS NO CARRINHO</h1>

          {carrinho.map((item, index) => {
            let arrayAdicionais = [];

            // Verifica o valor real de item.array_adicionais
            console.log(
              'Valor original de array_adicionais:',
              item.array_adicionais,
            );

            // Se array_adicionais já for um array, utiliza diretamente
            if (Array.isArray(item.array_adicionais)) {
              arrayAdicionais = item.array_adicionais;
            } else if (typeof item.array_adicionais === 'string') {
              // Se a string estiver com aspas simples, substituí-las por aspas duplas
              const formattedAdicionais = item.array_adicionais.replace(
                /'/g,
                '"',
              );

              // Tenta parsear a string corrigida para JSON
              if (
                formattedAdicionais.startsWith('[') &&
                formattedAdicionais.endsWith(']')
              ) {
                try {
                  arrayAdicionais = JSON.parse(formattedAdicionais);
                } catch (e) {
                  console.error('Erro ao converter array_adicionais:', e);
                }
              }
            }

            // Log do array convertido para garantir que os itens estejam presentes
            console.log('Array convertido de adicionais:', arrayAdicionais);

            return (
              <div
                key={index}
                className={`div_itens_carrinho ${
                  itensRemovidos.includes(index) ? 'div-carrinho-none' : ''
                }`}
              >
                <div className="div-price-itens-cart-title-adicionais">
                  <p>{item.item.nome}</p>
                  {Array.isArray(arrayAdicionais) &&
                  arrayAdicionais.length > 0 ? (
                    <div style={{ padding: '0px 10px' }}>
                      <p className="adicionas__carrinho__title">
                        Itens adicionais:
                      </p>
                      <div>
                        {arrayAdicionais.map((adicional, idx) => (
                          <p key={idx} className="adicionas__carrinho">
                            {adicional}
                          </p>
                        ))}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="div-price-itens-cart">
                  <ContadorCarrinho
                    quantidade={item.quantidade}
                    onIncrement={() => incrementarQuantidade(index)}
                    onDecrement={() => decrementarQuantidade(index)}
                  />
                </div>

                <div className="div-price-itens-cart div-price-cart-trash">
                  <p>
                    {`R$${(
                      item.quantidade * parseFloat(item.valor)
                    ).toLocaleString('pt-BR', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`}
                  </p>

                  {/* Garante que o valor seja um número */}
                  <button onClick={() => deleteCarrinho(index)}>
                    <DeleteIcon />
                  </button>
                </div>

                <hr className="linha_carrinho" />
              </div>
            );
          })}
        </div>

        <div
          className="container_checkout div-checkout"
          style={{ marginTop: '40px' }}
        >
          <h1>DETALHES</h1>
          <div className="div_itens_carrinho">
            <p>Total de itens</p>
            <p>{totalItens}</p>
          </div>

          <div className="div_itens_carrinho">
            <p>
              <b>Valor Total</b>
            </p>
            <p>
              <b>{` R$${valorTotal.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`}</b>
            </p>
          </div>
        </div>
        <div className="div-btn-carrinho">
          <button
            className="btn-finalizar-pedido"
            style={{
              background:
                cor2 === 'outra'
                  ? cor2
                  : {
                      'azul-escuro': '#3d489b',
                      azul: '#4892DF',
                      verde: '#3BBF9F',
                      cinza: '#A6B1B4',
                      'cinza-solido': '#f2f2f2',
                      vermelho: '#FF5E5E',
                      laranja: '#FF9A38',
                      amarelo: '#FFFF00',
                      roxo: '#800080',
                      dourado: '#b99c56',
                      'azul-marinho': '#1C7FBC',
                      'azul-solido': '#315694',
                      marrom: '#673C0D',
                      branco: '#FFFFFF',
                    }[cor2] || cor2,
              color: cor_letra,
            }}
            onClick={() => {
              if (totalItens > 0) {
                handleFinalizeOrder(); // Finaliza o pedido se houver itens
              } else {
                window.location.href = `/${cliente_id}/cardapio/?x=${apartamento}&id_cliente=${cliente_url}&session_control=${session_control}`; // Redireciona ao cardápio se o carrinho estiver vazio
              }
            }}
          >
            {totalItens > 0
              ? `Finalizar Pedido R$${valorTotal.toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`
              : 'Voltar ao Cardápio'}
          </button>
        </div>
      </div>

      {showSuccessMessage && (
        <Alert severity="success">Pedido enviado com sucesso.</Alert>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="container-modal">
            <h2 style={{ padding: '10px' }}>Finalizar Pedido</h2>
            Por favor, insira o número do local para o pedido.
            <br />
            <input
              type="number"
              value={local}
              onChange={handleLocalChange}
              placeholder="Local"
              className="input__local__carrinho"
            />
            <button
              className="btn-finalizar-pedido-modal"
              style={{
                background:
                  cor2 === 'outra'
                    ? cor2
                    : {
                        'azul-escuro': '#3d489b',
                        azul: '#4892DF',
                        verde: '#3BBF9F',
                        cinza: '#A6B1B4',
                        'cinza-solido': '#f2f2f2',
                        vermelho: '#FF5E5E',
                        laranja: '#FF9A38',
                        amarelo: '#FFFF00',
                        roxo: '#800080',
                        dourado: '#b99c56',
                        'azul-marinho': '#1C7FBC',
                        'azul-solido': '#315694',
                        marrom: '#673C0D',
                        branco: '#FFFFFF',
                      }[cor2] || cor2,
                color: cor_letra,
              }}
              onClick={handleModalFinalizeOrder}
            >
              Finalizar Pedido
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Carrinho;
