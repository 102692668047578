import { Link } from 'react-router-dom';
import './style.css';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
const { REACT_APP_URL_API } = process.env;

const ButtonLink = ({ children, ...props }) => {
  let [data, setData] = React.useState(null);
  const { cliente_id } = useParams();

  const cliente = cliente_id;

  var raw = JSON.stringify({
    cliente_id: cliente,
  });

  useEffect(() => {
    window
      .fetch(
        `${REACT_APP_URL_API}/apiv2/index/?x=38e278163b83e912a3a377824c55738e&id_cliente=497484adc29cb9cfa695d6e602188916&t=None&c=None&m=None&session_control=1722977110`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'omit',

          body: raw,
        },
      )
      .then((response) => response.json())
      .then((json) => setData(json));
  }, []);

  if (data === null) return null;

  return (
    <>
      <div className="box-button">
        <Link
          className="button-voucher"
          style={{
            background: `linear-gradient(135deg, ${data.cliente[0]?.cor_custom} 75%, ${data.cliente[0]?.cor_custom} 100%)`,
          }}
          {...props}
        >
          {children}
        </Link>
      </div>
    </>
  );
};

export default ButtonLink;
