import React, { useState, useEffect } from 'react';
import { HeaderCardapio } from '../../../Components/HeaderCardapio';
import Box from '@mui/material/Box';
import './style.css';
import { Link } from 'react-scroll';
import { useParams, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Modal from '@mui/material/Modal';
import { useCart } from '../../../CartContext';
import gluten from '../../../Assets/icons/gluten.png';
import acucar from '../../../Assets/icons/acucar.png';
import laticinios from '../../../Assets/icons/laticinios.png';
import vegano from '../../../Assets/icons/vegano.png';
import vegetariano from '../../../Assets/icons/vegetariano.png';

const { REACT_APP_URL_API } = process.env;
const style = {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'var(--background)',
  border: '2px solid var(--title)',
  boxShadow: 24,
  p: 4,
};

const generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const FacilitiesCardapio = () => {
  const [data, setData] = useState(null);
  const [menu, setMenu] = useState(null);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const { cliente_id, tipo_id, item_id } = useParams();
  const location = useLocation();
  const cookies = new Cookies();
  const token = cookies.get('csrftoken2');
  const itemId = parseInt(item_id, 10);

  const [session, setSession] = useState(
    localStorage.getItem('session') || generateUUID(),
  );
  const [abrir, setAbrir] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [modalText, setModalText] = useState(
    'Você realmente deseja incluir este item no carrinho?',
  );
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [selectedAdicionais, setSelectedAdicionais] = useState({});
  const [recommendations, setRecommendations] = useState([]);
  const [quantidade, setQuantidade] = useState(1);
  const [adicionaisSelecionados, setAdicionaisSelecionados] = useState([]);
  const [isAdded, setIsAdded] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const userIp = localStorage.getItem('ip_address');

  const handleAbrir = (item) => {
    setCurrentItem(item);
    // setRecommendations(
    //   getRecommendations(
    //     item,
    //     menu.tipo_refeicao.flatMap((tipo) =>
    //       tipo.categoria_cardapio.flatMap(
    //         (categoria) => categoria.item_cardapio,
    //       ),
    //     ),
    //   ),
    // );
    if (item.grupo_item_adicional || item.grupo_item_adicional_2) {
      setModalText('Selecione um acompanhamento');
      // setIsButtonDisabled(true);
    } else {
      setModalText('Você realmente deseja incluir este item no carrinho?');
      setIsButtonDisabled(false);
    }
    setAbrir(true);
    setSelectedAdicionais({});
  };

  const handleFechar = () => {
    setAbrir(false);
    setCurrentItem(null);
    setModalText('Você realmente deseja incluir este item no carrinho?');
    setIsButtonDisabled(false);
    setSelectedAdicionais({});
    setRecommendations([]);
  };

  const handleCheckboxChange = (grupoIndex, adicionalIndex) => {
    const newSelectedAdicionais = { ...selectedAdicionais };
    const key = `adicional-${grupoIndex}-${adicionalIndex}`;
    if (newSelectedAdicionais[key]) {
      delete newSelectedAdicionais[key];
    } else {
      newSelectedAdicionais[key] = true;
    }
    setSelectedAdicionais(newSelectedAdicionais);
    setIsButtonDisabled(Object.keys(newSelectedAdicionais).length === 0);
  };

  const { addItemToCart } = useCart();

  const queryParams = new URLSearchParams(location.search);
  const apartamento = queryParams.get('x');
  const cliente_url = queryParams.get('id_cliente');
  const session_control = queryParams.get('session_control');
  const headers = new Headers({
    'Content-Type': 'application/json',
    'X-CSRFToken': token,
  });

  const userLanguage = navigator.language || navigator.userLanguage; // Obtém o idioma do navegador

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userIp = localStorage.getItem('ip_address');

        const headers = new Headers({
          'Content-Type': 'application/json',
          'X-CSRFToken': token,
        });

        const body = JSON.stringify({
          cliente_id: cliente_id,
          ip_address: userIp,
          language: userLanguage,
          session: session,
        });

        const response = await fetch(
          `${REACT_APP_URL_API}/apiv2/index/?x=${apartamento}&id_cliente=${cliente_url}&session_control=${session_control}`,
          {
            method: 'POST',
            credentials: 'omit',

            headers,
            body,
          },
        );
        const result = await response.json();
        setData(result);
      } catch (error) {
        setError(error);
      }
    };

    const fetchMenu = async () => {
      try {
        const userIp = localStorage.getItem('ip_address');

        const headers = new Headers({
          'Content-Type': 'application/json',
          'X-CSRFToken': token,
        });

        const body = JSON.stringify({
          cliente_id: cliente_id,
          ip_address: userIp,
          session: session,
        });

        const response = await fetch(
          `${REACT_APP_URL_API}/apiv2/menu/?x=${apartamento}&id_cliente=${cliente_url}&session_control=${session_control}`,
          {
            method: 'POST',
            credentials: 'omit',

            headers,
            body,
          },
        );
        const result = await response.json();
        setMenu(result);
      } catch (error) {
        setError(error);
      }
    };

    fetchData();
    fetchMenu();
  }, [cliente_id, apartamento, cliente_url, session, session_control]);

  if (data === null || menu === null) return null;

  const cor1 = data.cliente[0]?.cor_custom;
  const cor2 = data.cliente[0]?.cor_1;
  const cor_letra = data.cliente[0]?.cor_letra;
  const tipoRefeicao = menu.tipo_refeicao.find(
    (tipo) => tipo.id === parseInt(tipo_id),
  );

  if (!tipoRefeicao) return null;

  const categorias = tipoRefeicao.categoria_cardapio.filter(
    (categoria) => categoria.status === true,
  );

  const filteredCategories = categorias
    .map((categoria) => {
      const filteredItems = categoria.item_cardapio.filter(
        (item) =>
          item.status === true &&
          (item.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.descricao.toLowerCase().includes(searchTerm.toLowerCase())),
      );
      return {
        ...categoria,
        filteredItems,
      };
    })
    .filter((categoria) => categoria.filteredItems.length > 0);

  // Itens sem categoria

  const itemsSemCategoria = tipoRefeicao.item_cardapio_sem_cat.filter(
    (item) =>
      item.status === true &&
      (item.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.descricao.toLowerCase().includes(searchTerm.toLowerCase())),
  );

  if (itemsSemCategoria.length > 0) {
    filteredCategories.push({
      nome: tipoRefeicao.nome, // Altere 'Sem Categoria' para tipoRefeicao.nome
      filteredItems: itemsSemCategoria,
    });
  }
  const habilitaPedido = data.cliente[0]?.pedido_menu;

  const handleAdicionaisChange = (adicionalId) => {
    setAdicionaisSelecionados((prevAdicionais) =>
      prevAdicionais.includes(adicionalId)
        ? prevAdicionais.filter((id) => id !== adicionalId)
        : [...prevAdicionais, adicionalId],
    );
  };
  const findItem = (menu) => {
    for (const tipo of menu.tipo_refeicao) {
      for (const categoria of tipo.categoria_cardapio) {
        const item = categoria.item_cardapio.find((item) => item.id === itemId);
        if (item) return item;
      }

      if (tipo.item_cardapio_sem_cat) {
        const itemSemCat = tipo.item_cardapio_sem_cat.find(
          (item) => item.id === itemId,
        );
        if (itemSemCat) return itemSemCat;
      }
    }

    return null;
  };

  const handleAddToCart = () => {
    setIsAdding(true); // Iniciar animação de crescimento

    if (!currentItem || !currentItem.id) {
      console.error('Item não encontrado ou ID do item inválido.');
      setIsAdding(false); // Parar animação em caso de erro
      return;
    }

    // Obter os IDs dos itens adicionais selecionados
    const adicionaisSelecionadosIds = Object.keys(selectedAdicionais).map(
      (key) => {
        const [, grupoIndex, adicionalIndex] = key.split('-');
        const grupo =
          grupoIndex === '0'
            ? currentItem.grupo_item_adicional
            : currentItem.grupo_item_adicional_2;
        return grupo.items[adicionalIndex][1]; // Retorna o ID do adicional
      },
    );

    const payload = {
      cliente_id: cliente_id,
      ip_address: userIp,
      session: session,
      operation: 'add',
      items: [
        {
          id: currentItem.id, // Use currentItem.id para garantir que o item correto seja adicionado
          quantity: quantidade,
          additional_items: adicionaisSelecionadosIds, // Enviando os itens adicionais
        },
      ],
    };

    fetch(
      `${REACT_APP_URL_API}/apiv2/cart/update/?x=${apartamento}&id_cliente=${cliente_url}&session_control=${session_control}`,
      {
        method: 'POST',
        credentials: 'omit',
        headers: headers,
        body: JSON.stringify(payload),
      },
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Erro ${response.status}: ${response.statusText}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log('Item adicionado ao carrinho:', data);
        addItemToCart(quantidade);

        setIsAdded(true); // Alterar para o estado "adicionado"

        // Após 2 segundos, voltar ao estado original do botão
        setTimeout(() => {
          setIsAdded(false);
          setIsAdding(false); // Parar animação de crescimento
        }, 2000);

        // Atualizar a página após 1 segundo
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch((error) => {
        console.error('Erro ao adicionar item ao carrinho:', error);
        setIsAdded(false);
        setIsAdding(false); // Parar animação em caso de erro
      });
  };

  const renderGrupoItems = (grupo, grupoIndex) => {
    return grupo.items.map((item, index) => (
      <div key={index} className="div-opcionais">
        <input
          type="checkbox"
          id={`adicional-${grupoIndex}-${index}`}
          value={item[0]} // Usar o ID do item adicional
          onChange={() => handleCheckboxChange(grupoIndex, index)}
          checked={
            selectedAdicionais[`adicional-${grupoIndex}-${index}`] || false
          }
        />
        <label htmlFor={`adicional-${grupoIndex}-${index}`}>{item[1]}</label>
      </div>
    ));
  };

  return (
    <div>
      <HeaderCardapio searchTerm={searchTerm} setSearchTerm={setSearchTerm} />

      <div className="categorias categorias-cardapio">
        <ul>
          {filteredCategories.map((categoria) => (
            <li key={categoria.id}>
              <Link
                className={categoria.nome}
                to={categoria.nome}
                spy={true}
                smooth={true}
                duration={500}
                offset={-99}
                activeClass="ActiveLink"
              >
                {categoria.nome}
              </Link>
            </li>
          ))}
        </ul>
      </div>

      <Box
        sx={{
          maxWidth: '930px',
          margin: '120px auto',
          left: '1%',
          right: '1%',
          bgcolor: 'var(--background-main)',
        }}
      >
        {filteredCategories.length === 0 ? (
          <div className="no__results">
            <ErrorOutlineIcon />
            <p>Nenhum item encontrado.</p>
          </div>
        ) : (
          filteredCategories.map((categoria) => (
            <div
              className={categoria.nome}
              name={categoria.nome}
              id={categoria.nome}
              key={categoria.id}
            >
              <div className="container-clube-voucher">
                <h2
                  style={{
                    color: 'var(--secondary)',
                    fontSize: '16px',
                    fontWeight: 300,
                    padding: '0px 0px 10px 10px',
                  }}
                >
                  {categoria.nome}
                </h2>
                {categoria.filteredItems.map((item) => (
                  <div className="box container-card-cardapio" key={item.id}>
                    <div
                      className={`cardapio-container ${
                        !item.img ? 'cardapio-container2' : ''
                      }`}
                    >
                      <div>
                        <a
                          href={`/${cliente_id}/cardapioitem/${item.id}/?x=${apartamento}&id_cliente=${cliente_url}&session_control=${session_control}`}
                          key={item.id}
                        >
                          {item.img && (
                            <img
                              src={`${REACT_APP_URL_API}` + item.img}
                              alt={item.nome}
                            />
                          )}
                        </a>
                      </div>

                      <div className="div_teste_cardapio">
                        <div className="conteudo-cardapio">
                          <a
                            href={`/${cliente_id}/cardapioitem/${item.id}/?x=${apartamento}&id_cliente=${cliente_url}&session_control=${session_control}`}
                            key={item.id}
                          >
                            <p>
                              <b>{item.nome}</b>
                              {console.log('item carrinho teste', item)}
                            </p>
                            <p>{item.descricao}</p>
                            <div className="img-restricao">
                              {console.log('item carrinho teste', item)}
                              {item.gluten_free && (
                                <img src={gluten} alt="gluten" />
                              )}

                              {item.sugar_free && (
                                <img src={acucar} alt="acucar" />
                              )}

                              {item.dairy_free && (
                                <img src={laticinios} alt="laticinios" />
                              )}
                              {item.vegan && <img src={vegano} alt="vegano" />}
                              {item.vegetarian && (
                                <img src={vegetariano} alt="gluten" />
                              )}
                            </div>
                          </a>
                        </div>

                        <div className="div_cardapio_price">
                          <p className="price-cardapio">
                            R${' '}
                            {parseFloat(item.valor).toLocaleString('pt-BR', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </p>

                          {habilitaPedido === true &&
                          item.habilita_pedido === true ? (
                            <button
                              className="btn_cardapio"
                              onClick={() => handleAbrir(item)}
                              style={{
                                background:
                                  cor2 === 'outra'
                                    ? cor2
                                    : {
                                        'azul-escuro': '#3d489b',
                                        azul: '#4892DF',
                                        verde: '#3BBF9F',
                                        cinza: '#A6B1B4',
                                        'cinza-solido': '#f2f2f2',
                                        vermelho: '#FF5E5E',
                                        laranja: '#FF9A38',
                                        amarelo: '#FFFF00',
                                        roxo: '#800080',
                                        dourado: '#b99c56',
                                        'azul-marinho': '#1C7FBC',
                                        'azul-solido': '#315694',
                                        marrom: '#673C0D',
                                        branco: '#FFFFFF',
                                      }[cor2] || cor2,
                                color: cor_letra,
                              }}
                            >
                              Adicionar
                            </button>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))
        )}
      </Box>

      <Modal
        open={abrir}
        onClose={handleFechar}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="container-modal">
            {currentItem && (
              <>
                <h2 id="modal-modal-title">{modalText}</h2>
                <div className="modal_cardapio_img">
                  <img
                    src={`${REACT_APP_URL_API}${currentItem.img}`}
                    alt={currentItem.nome}
                  />
                </div>
                <div className="modal-conteudo">
                  <p>{currentItem.nome}</p>
                  <p>{currentItem.descricao}</p>
                  {/* <p className="price-cardapio">R$ {currentItem?.valor}</p> */}
                </div>

                {/* Renderização dos itens opcionais */}
                {currentItem.grupo_item_adicional && (
                  <h3 className="title-h3-cardapio">
                    Selecione os adicionais:
                  </h3>
                )}

                {currentItem.grupo_item_adicional &&
                  Array.isArray(currentItem.grupo_item_adicional.items) &&
                  currentItem.grupo_item_adicional.items.length > 0 && (
                    <div className="opcionais-container">
                      <h4 className="title-h3-cardapio">
                        {currentItem.grupo_item_adicional.group_name}
                      </h4>
                      {renderGrupoItems(currentItem.grupo_item_adicional, 0)}
                    </div>
                  )}

                {currentItem.grupo_item_adicional_2 &&
                  Array.isArray(currentItem.grupo_item_adicional_2.items) &&
                  currentItem.grupo_item_adicional_2.items.length > 0 && (
                    <div className="opcionais-container">
                      <h4 className="title-h3-cardapio">
                        {currentItem.grupo_item_adicional_2.group_name}
                      </h4>
                      {renderGrupoItems(currentItem.grupo_item_adicional_2, 1)}
                    </div>
                  )}

                {Array.isArray(currentItem.grupo_item_adicional_2) &&
                  currentItem.grupo_item_adicional_2.length > 0 && (
                    <div className="opcionais-container">
                      {currentItem.grupo_item_adicional_2.map(
                        (grupo, grupoIndex) => (
                          <div key={grupoIndex}>
                            <h4 className="title-h3-cardapio">{grupo.nome}</h4>
                            {renderGrupoItems(grupo, grupoIndex)}
                          </div>
                        ),
                      )}
                    </div>
                  )}
              </>
            )}

            <button
              className={`btn-cardapio-add ${isAdded ? 'added' : ''} ${
                isAdding ? 'adding' : ''
              }`}
              style={{
                background: isAdded
                  ? '#3BBF9F'
                  : cor2 === 'outra'
                  ? cor2
                  : {
                      azul: '#4892DF',
                      verde: '#3BBF9F',
                      cinza: '#A6B1B4',
                      'cinza-solido': '#f2f2f2',
                      'azul-escuro': '#3d489b',
                      vermelho: '#FF5E5E',
                      laranja: '#FF9A38',
                      amarelo: '#FFFF00',
                      roxo: '#800080',
                      dourado: '#b99c56',
                      'azul-marinho': '#1C7FBC',
                      'azul-solido': '#315694',
                      marrom: '#673C0D',
                      branco: '#FFFFFF',
                    }[cor2] || cor2,
                color: isAdded ? '#fff' : cor_letra,
              }}
              onClick={handleAddToCart}
              // disabled={isButtonDisabled}
            >
              {isAdded ? (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-check"
                  >
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                  Adicionado
                </>
              ) : (
                `Adicionar: R$${(
                  currentItem?.valor * quantidade
                ).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
`
              )}
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default FacilitiesCardapio;
